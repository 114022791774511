import { ChangeEvent, FormEvent, useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { IatTaskResult, IatTask, IatConfigUpdate } from '../interfaces/IatConfigUpdate';
import { trackingHpsdUpdateRequest, trackingIatUpdateRequest } from '../services/apiSys';
import handleError from '../utils/handleError';
import { IatTracking } from '../interfaces/IatTracking';
import { ProductType } from '../interfaces/IatConfigResultDetail';
import StatusIcon from './StatusIcon';
import { HpsdTaskTrackingUpdate, HpsdTask, HpsdTaskResult } from '../interfaces/HpsdTaskTrackingUpdate';
import { HpsdTracking } from '../interfaces/HpsdTracking';

type Iat = {
  idVantive: number;
  productType: ProductType; /** ProductType object returned from IAT search.  */
  taskProduct: string; /** The product of the task, ex: LP, OLT, SWT, etc... */
  taskType: 'config' | 'certification'; /** The type of the task to be perfomed: 'config' for Configuration and 'certification' for Certification. */
};

type Hpsd = {
  designador: string;
  // productType: ProductType;
  taskProduct: string;
  taskType: 'config' | 'certification';
};

type Props = {
  iat?: Iat;
  hpsd?: Hpsd;
};

/**
 * Component to perform a manualTask log in task_tracking
 * @param iat IAT params to execute a manual task
 * @param hpsd HPSD params to execute a manual task
 * @returns 
 */
const ManualTask = ({ iat, hpsd }: Props) => {
  const [manualLog, setManualLog] = useState<string>('');
  const [manualResult, setManualResult] = useState<IatTracking | HpsdTracking>();
  const [error, setError] = useState<string>();

  const taskType = iat?.taskType || hpsd?.taskType;

  const onChangeManualLog = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setManualResult(undefined);
    setManualLog(e.target.value);
  };

  const handleManualSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    if (iat) {
      iatUpdateRequest(iat);
    } else if (hpsd) {
      hpsdUpdateRequest(hpsd);
    }

  };

  const iatUpdateRequest = async (iat: Iat) => {
    const manualTask: IatTaskResult = {
      idVantive: iat.idVantive,
      messageResult: iat.taskType === 'config' ? "Configuração Manual" : "Certificação Manual",
      productType: iat.productType,
      results: [
        {
          completeLogs: manualLog,
          idStatusResult: 1,
          headerDescription: iat.taskType === 'config' ? "Configuração Manual" : "Certificação Manual",
          startDate: new Date().toISOString(),
          messageResult: '',
          networkCommandResults: [{
            idStatus: 1,
            log: manualLog,
            procedureDescription: iat.taskType === 'config' ? "Configurado Manualmente" : "Certificado Manualmente",
            result: iat.taskType === 'config' ? "Configurado com sucesso" : "Certificado com sucesso",
            step: 1
          }]
        }
      ]
    };
    const task: IatTask = {
      taskType: iat.taskType,
      description: iat.taskType === 'config' ? "Configuração Manual" : "Certificação Manual",
      status: "COMPLETED",
      result: manualTask
    };
    const body: IatConfigUpdate = {
      id_vantive: iat.idVantive,
      product: iat.taskProduct,
      status: iat.taskType === 'config' ? 'IN PROGRESS' : 'COMPLETED',
      tasks: [task],
    };
    try {
      const resp = await trackingIatUpdateRequest(body);
      if (resp.data) {
        setManualResult(resp.data);
      }
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const hpsdUpdateRequest = async (hpsd: Hpsd) => {
    try {
      const result: HpsdTaskResult = {
        designador: hpsd.designador,
        messageResult: hpsd.taskType === 'config' ? "Configuração Manual" : "Certificação Manual",
        results: [
          {
            completeLogs: manualLog,
            idStatusResult: 1,
            headerDescription: hpsd.taskType === 'config' ? "Configuração Manual" : "Certificação Manual",
            startDate: new Date().toISOString(),
            messageResult: '',
            networkCommandResults: [{
              idStatus: 1,
              log: manualLog,
              procedureDescription: hpsd.taskType === 'config' ? "Configuração Manual" : "Certificação Manual",
              result: hpsd.taskType === 'config' ? "Configurado com sucesso" : "Certificado com sucesso",
              step: 1
            }]
          }
        ]
      };

      const task: HpsdTask = {
        taskType: hpsd.taskType,
        description: hpsd.taskType === 'config' ? "Configuração de designador Manual" : "Certificação de designador Manual",
        status: "COMPLETED",
        result: result,
      };

      const body: HpsdTaskTrackingUpdate = {
        designador: hpsd.designador,
        product: 'INTERNET',
        status: hpsd.taskType === 'config' ? "IN PROGRESS" : "COMPLETED",
        tasks: [task]
      };
      const resp = await trackingHpsdUpdateRequest(body);
      if (resp.data) {
        setManualResult(resp.data);
      }
    } catch (error) {
      handleError({ error, setError });
    }
  };


  return (
    <>
      {error && (
        <p className='error'>{error}</p>
      )}
      <Box
        component='form'
        display={'flex'}
        flexDirection={'column'}
        width={"100%"}
        alignItems={'center'}
        onSubmit={handleManualSubmit}
      >
        {taskType === 'config' && (
          <>
            <p className='error'>Configuração resultou em falha</p>
            <p>Se optar, insira o log da configuração manualmente</p>
          </>
        )}
        <TextField
          label="Log"
          multiline
          fullWidth
          rows={4}
          value={manualLog}
          onChange={onChangeManualLog}
          required
        />
        <Button
          variant='contained'
          className='button'
          type='submit'
          sx={{ margin: 1 }}
          disabled={!!manualResult}
        >
          {taskType === 'config' && `Configurar Manualmente`}
          {taskType === 'certification' && `Finalizar Manualmente`}
        </Button>
      </Box>
      {manualResult && taskType === 'config' && (
        <>
          <Typography textAlign='center' >
            <StatusIcon status={manualResult.status === 'IN PROGRESS'} text={['Configuração Realizada com Sucesso', 'Falha na configuração manual']} />
          </Typography>
          <Typography>Finalize a ordem manualmente</Typography>
          {iat && (
            <ManualTask
              iat={iat}
            />
          )}
          {hpsd && (
            <ManualTask
              hpsd={hpsd}
            />
          )}
        </>
      )}
      {manualResult && taskType === 'certification' && (
        <Typography textAlign='center' >
          <StatusIcon status={manualResult.status === 'COMPLETED'} text={['Ordem Finalizada', 'Ordem não finalizada']} />
        </Typography>
      )}
    </>
  );
};

export default ManualTask;