import { FormEvent, useEffect, useState } from 'react';
import { InternetFacility } from '../../interfaces/InternetFacility';
import { Autocomplete, Box, Button, Skeleton, TextField, Typography } from '@mui/material';
import { hpsdFacilitiesRequest } from '../../services/apiSys';
import handleError from '../../utils/handleError';
import ManobraRecursoDisplay from '../../components/ManobraRecursoDisplay';

type Props = {};

const ManobraRecursos = (props: Props) => {
  const [designadorId, setDesignadorId] = useState<string>('');
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [facilidadeResponse, setFacilidadeResponse] = useState<InternetFacility>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    // Load search history from localStorage when the component mounts
    const storedHistory = localStorage.getItem('internetIdSearchHistory');
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  const saveSearchHistory = (history: string[]) => {
    // Save search history to localStorage
    localStorage.setItem('internetIdSearchHistory', JSON.stringify(history));
  };

  const handleSearchFacilidade = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      setError("");
      setFacilidadeResponse(undefined);
      if (designadorId.trim() !== '' && !searchHistory.includes(designadorId)) {
        // Add the search input to the history
        const updatedHistory = [designadorId, ...searchHistory.slice(0, 9)];
        setSearchHistory(updatedHistory);
        // Save the updated history to localStorage
        saveSearchHistory(updatedHistory);
      }
      const res = await hpsdFacilitiesRequest(designadorId);
      setFacilidadeResponse(res.data);
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="div"
      className='main'
    >
      <Typography variant='h5'>Manobra de Recursos</Typography>
      <Box
        component="form"
        onSubmit={handleSearchFacilidade}
        display={'flex'}
        width={'100%'}
      >
        <Autocomplete
          id='input-internet-id'
          freeSolo
          options={searchHistory}
          fullWidth
          value={designadorId}
          onInputChange={(e, value) => setDesignadorId(value)}
          renderInput={(params) => <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              className: 'query-input',
            }}
            label="Designador de acesso"
            required
          />
          }
        />
        <Button
          className='button'
          sx={{ marginLeft: '1em' }}
          variant="contained"
          color="primary"
          disabled={loading}
          type='submit'
        >
          {loading ? 'Buscando...' : 'Buscar'}
        </Button>
      </Box>
      {facilidadeResponse && (
        <ManobraRecursoDisplay dataFacility={facilidadeResponse} />
      )}
      {loading && <Skeleton variant='rounded' width='100%' sx={{ marginTop: 1 }} height={500} />}
      {error && <p className='error'>{error}</p>}
    </Box >
  );
};

export default ManobraRecursos;