import React from 'react';
import { IVivo2Circuit } from '../interfaces/IVivo2Circuit';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import Vivo2CircuitForm from './Vivo2CircuitForm';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (installation: IVivo2Circuit) => void;
  installation?: IVivo2Circuit;
};

const Vivo2CircuitModal = ({ open, onClose, onSave, installation }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{installation ? "Editar Instalação" : "Adicionar Nova Instalação"}</DialogTitle>
      <DialogContent>
        <Vivo2CircuitForm installation={installation} onSave={onSave} />
      </DialogContent>
      <DialogActions>
        <Button className='button' onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Vivo2CircuitModal;