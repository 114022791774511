import { SetStateAction } from "react";

/**
 * 
 * @param obj 
 * @param keys 
 * @param value 
 * @returns 
 */
function setNestedValue<T,>(obj: T, keys: string[], value: string | number): T {
  if (keys.length === 1) {
    return { ...obj, [keys[0]]: value } as T;
  }

  const [key, ...rest] = keys;

  return {
    ...obj,
    [key]: setNestedValue(obj[key as keyof T], rest, value),
  } as T;
};

/**
 * 
 * @param value 
 * @param combinedKey 
 * @param setUpdatedData 
 */
export function handleInputChange<T>(value: string | number, combinedKey: string, setUpdatedData: (value: SetStateAction<T>) => void) {
  const keys = combinedKey.split(".");

  setUpdatedData((prevState) => (prevState && {
    ...prevState,
    [keys[0]]: setNestedValue(prevState[keys[0] as keyof T], keys.slice(1), value),
  }));
};
