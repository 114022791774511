import styles from './IpLightCard.module.css';
import { IPLightInfoData } from '../interfaces/IpLightInfo';
import { Box, Card, CircularProgress, Grid, Modal, Tab, Tabs, Typography } from '@mui/material';
import { CardContent } from '@mui/material';
import { Divider } from '@mui/material';
import { useState } from 'react';
import PhotoViewer from './PhotoViewer';
import mainPhoto from "../assets/cpes/1_geral.png";
import forwardPhoto from "../assets/cpes/1_frente.png";
import versePhoto from "../assets/cpes/1_atras.png";
import StatusIcon from './StatusIcon';
import { Button } from '@mui/material';
import { pingRequest } from '../services/apiWeb';
import { PingResponseData } from '../interfaces/PingResponse';

type Props = {
  ipLight: IPLightInfoData;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const IpLightCard = ({ ipLight }: Props) => {

  const [tabValue, setTabValue] = useState(0);
  const [openPingModal, setOpenPingModal] = useState(false);
  const [pingResponse, setPingResponse] = useState<PingResponseData | null>(null);
  const [loading, setLoading] = useState(false);


  const handleChange = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const handleClosePingModal = () => {
    setOpenPingModal(false);
  };

  const handlePingButton = async () => {
    try {
      setLoading(true);
      setOpenPingModal(true);
      const res = await pingRequest({ host: ipLight.wan_ipv4_address });
      console.log(res.data);
      setPingResponse(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} className={styles.main}>
          <Grid item container spacing={2} xs={8} >
            <Grid item xs={12}>
              <Card className={styles.card}>
                <div className={styles.net_info}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
                      <Tab label="WAN" {...a11yProps(0)} />
                      <Tab label="LAN" {...a11yProps(1)} />
                      <Tab label="SNMP" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabValue} index={0}>
                    <Typography className={styles.title}>Informações WAN</Typography>
                    <div className={styles.params}>
                      <div>
                        Potência GPON (dBm):
                        <span> {ipLight.wan_potencia_gpon ? ipLight.wan_potencia_gpon : `-`}</span>
                      </div>
                      <div>
                        VLAN:
                        <span>{ipLight.wan_vlan ? ipLight.wan_vlan : `-`}</span>
                      </div>
                      <div>
                        Endereço IPv4:
                        <span>{ipLight.wan_ipv4_address ? ipLight.wan_ipv4_address : `-`}</span>
                      </div>
                      <div>
                        Sub-red Mask IPv4:
                        <span>{ipLight.wan_subnet_mask ? ipLight.wan_subnet_mask : `-`}</span>
                      </div>
                      <div>
                        Gateway IPv4:
                        <span>{ipLight.wan_gateway_ip_address ? ipLight.wan_gateway_ip_address : `-`}</span>
                      </div>
                      <div>
                        Prefixo IPv4:
                        <span>{ipLight.wan_ipv4_prefix ? ipLight.wan_ipv4_prefix : `-`}</span>
                      </div>
                      <div>
                        Endereço IPv6:
                        <span>{ipLight.wan_ipv6_address ? ipLight.wan_ipv6_address : `-`}</span>
                      </div>
                      <div>
                        Sub-red Mask IPv6:
                        <span>{ipLight.wan_subnet_mask_ipv6 ? ipLight.wan_subnet_mask_ipv6 : `-`}</span>
                      </div>
                      <div>
                        Gateway IPv6:
                        <span> {ipLight.wan_gateway_ipv6 ? ipLight.wan_gateway_ipv6 : `-`}</span>
                      </div>
                      <div>
                        DNS Primário IPv4:
                        <span>{ipLight.wan_primary_dns ? ipLight.wan_primary_dns : `-`}</span>
                      </div>
                      <div>
                        DNS Secundário IPv4:
                        <span>{ipLight.wan_secondary_dns ? ipLight.wan_secondary_dns : `-`}</span>
                      </div>
                      <div>
                        DNS Primário IPv6:
                        <span>{ipLight.wan_primary_dns_ipv6 ? ipLight.wan_primary_dns_ipv6 : `-`}</span>
                      </div>
                      <div>
                        DNS Secundário IPv6:
                        <span>{ipLight.wan_secondary_dns_ipv6 ? ipLight.wan_secondary_dns_ipv6 : `-`}</span>
                      </div>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={1}>
                    <Typography className={styles.title}>Informações LAN</Typography>
                    <div className={styles.params}>
                      <div>
                        DHCP Server:
                        <span>{ipLight.lan_dhcp_table ? `Habilitado` : `Desabilitado`}</span>
                      </div>
                      <div>
                        Gateway IPv4 LAN:
                        <span>{ipLight.lan_gateway_ipv4 ? ipLight.lan_gateway_ipv4 : `-`}</span>
                      </div>
                      <div>
                        Sub-rede Mask IPv4:
                        <span>{ipLight.lan_subnet_mask ? ipLight.lan_subnet_mask : `-`}</span>
                      </div>
                      <div>
                        Lease Time (s):
                        <span>{ipLight.lan_lease_time ? ipLight.lan_lease_time : `-`}</span>
                      </div>
                      <div>
                        <label>
                          IPv4 Address
                          <span>Start: {ipLight.lan_ipv4_address_start ? ipLight.lan_ipv4_address_start : `-`} End: {ipLight.lan_ipv4_address_enc ? ipLight.lan_ipv4_address_enc : `-`}</span>
                        </label>
                      </div>
                      <div>
                        Servidor DNS Automático:
                        <span>{ipLight.lan_automatic_server ? `Habilitado` : `Desabilitado`}</span>
                        <p>Primário: {ipLight.lan_primary_automatic_server ? ipLight.lan_primary_automatic_server : `-`}</p>
                        <p>Secundário: {ipLight.lan_secondary_automatic_server ? ipLight.lan_secondary_automatic_server : `-`}</p>
                      </div>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={2}>
                    <Typography className={styles.title}>Informações SNMP</Typography>
                    <div className={styles.params}>
                      <div>
                        Server Port:
                        <span>{ipLight.snmp_server_port ? ipLight.snmp_server_port : `-`}</span>
                      </div>
                      <div>
                        Server Access:
                        <span>{ipLight.snmp_server_access ? ipLight.snmp_server_access : `-`}</span>
                      </div>
                      <div>
                        SNMP V3:
                        <span>{ipLight.snmp_snmpv3 ? ipLight.snmp_snmpv3 : `-`}</span>
                      </div>
                      <div>
                        Get Community:
                        <span>{ipLight.snmp_get_community ? ipLight.snmp_get_community : `-`}</span>
                      </div>
                      <div>
                        Set Community:
                        <span>{ipLight.snmp_set_community ? ipLight.snmp_set_community : `-`}</span>
                      </div>
                      <div>
                        Trap Community:
                        <span>{ipLight.snmp_trap_community ? ipLight.snmp_trap_community : `-`}</span>
                      </div>
                      <div>
                        IPV4 Trap Destination:
                        <span>{ipLight.snmp_ipv4_trap_destination ? ipLight.snmp_ipv4_trap_destination : `-`}</span>
                      </div>
                      <div>
                        IPV6 Trap Destination:
                        <span>{ipLight.snmp_ipv6_trap_destination ? ipLight.snmp_ipv6_trap_destination : `-`}</span>
                      </div>
                    </div>
                  </CustomTabPanel>
                </div>
              </Card>
            </Grid>
            <Grid item xs={6} className={styles.wifi}>
              <Card className={styles.card}>
                <CardContent className={styles.content}>
                  <div className={styles.wifi_header}>
                    <Typography className={styles.title}>Wi-Fi 2.4GHz</Typography>
                    <StatusIcon status={ipLight.wifi24_status_wifi24} text={["Habilitado", "Desabilitado"]} />
                  </div>
                  <div className={styles.params_wifi}>
                    <div>
                      SSID
                      <span>{ipLight.wifi24_ssid ? ipLight.wifi24_ssid : '-'}</span>
                    </div>
                    <div>
                      Password
                      <span>{ipLight.wifi24_password ? ipLight.wifi24_password : '-'}</span>
                    </div>
                    <div>
                      Modo Segurança
                      <span>{ipLight.wifi24_modo_seguranca ? ipLight.wifi24_modo_seguranca : '-'}</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} className={styles.wifi}>
              <Card className={styles.card}>
                <CardContent className={styles.content}>
                  <div className={styles.wifi_header}>
                    <Typography className={styles.title}>Wi-Fi 5GHz</Typography>
                    <StatusIcon status={ipLight.wifi5_status_wifi5} text={["Habilitado", "Desabilitado"]} />
                  </div>
                  <div className={styles.params_wifi}>
                    <div>
                      SSID
                      <span>{ipLight.wifi5_ssid ? ipLight.wifi5_ssid : '-'}</span>
                    </div>
                    <div>
                      Password
                      <span>{ipLight.wifi5_password ? ipLight.wifi5_password : '-'}</span>
                    </div>
                    <div>
                      Modo Segurança
                      <span>{ipLight.wifi5_modo_seguranca ? ipLight.wifi5_modo_seguranca : '-'}</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item container xs={4} >
            <Grid item xs={12}>
              <Card className={`${styles.card} ${styles.device_info}`}>
                <CardContent className={styles.cpe_date}>
                  {ipLight.created_at}
                </CardContent>
                <CardContent className={styles.cpe_info}>
                  <label>
                    ID Vantive:
                    <span>{ipLight.client_id ? ipLight.client_id : `-`}</span>
                  </label>
                  <Divider orientation="vertical" flexItem />
                  <label>
                    Login Técnico:
                    <span>{ipLight.login_tecnico ? ipLight.login_tecnico : `-`}</span>
                  </label>
                </CardContent>
                <Divider variant='middle' />
                <CardContent>
                  <PhotoViewer mainPhoto={mainPhoto} relatedPhotos={[forwardPhoto, versePhoto]} />
                </CardContent>
                <Divider variant='middle' />
                <CardContent className={styles.cpe_info}>
                  <label>
                    Modelo HGU:
                    <span>{ipLight.info_hgu && ipLight.info_hgu.modelo ? ipLight.info_hgu.modelo : `-`}</span>
                  </label>
                  <Divider orientation="vertical" flexItem />
                  <label>
                    Serial Number:
                    <span>{ipLight.info_hgu && ipLight.info_hgu.serial_number ? ipLight.info_hgu.serial_number : `-`}</span>
                  </label>
                </CardContent>
                <Divider variant='middle' />
                <CardContent className={styles.cpe_info}>
                  <label>
                    Firmware:
                    <span>{ipLight.info_hgu && ipLight.info_hgu.firmware_version ? ipLight.info_hgu.firmware_version : `-`}</span>
                  </label>
                  <Divider orientation="vertical" flexItem />
                  <label>
                    Input Rede:
                    <span>{ipLight.input_rede ? ipLight.input_rede : `-`}</span>
                  </label>
                </CardContent>
                <Divider variant='middle' />
                <CardContent className={styles.cpe_status}>
                  <span> WAN: </span>
                  <StatusIcon statusDescription={{ status: ipLight.wan_status_wan }} />
                </CardContent>
                <CardContent className={styles.cpe_status}>
                  <span> LAN: </span>
                  <StatusIcon statusDescription={{ status: ipLight.lan_status_lan }} />
                </CardContent>
                <CardContent className={styles.cpe_status}>
                  <span> SNMP: </span>
                  <StatusIcon statusDescription={{ status: ipLight.snmp_status_snmp }} />
                </CardContent>
                <CardContent className={styles.cpe_status}>
                  <span> ACS: </span>
                  <StatusIcon status={ipLight.acs_enable} text={["Aprovado", "Reprovado"]} />
                </CardContent>
                <Divider variant='middle' />
                <CardContent className={styles.hidden}>
                  <Button className={styles.button} onClick={handlePingButton} disabled>
                    TESTE PING
                  </Button>
                </CardContent>
                <Divider variant='middle' />
                <CardContent className={styles.cpe_bottom}>
                  <div>
                    App Version:
                  </div>
                  <div className={styles.version}>
                    {ipLight.app_version ? ipLight.app_version : `-`}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Modal open={openPingModal} onClose={handleClosePingModal} >
        <Box className={styles.modal} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          {loading && <CircularProgress sx={{ margin: "0 auto" }} />}
          {!loading && (
            <>
              <Typography variant="h6">{`Disparando para ${ipLight.wan_ipv4_address} com 32 bytes de dados`}</Typography>
              <Divider className={styles.divider} variant='fullWidth' />
              <Typography variant="h6">{`Estatísticas do ping para ${ipLight.wan_ipv4_address}`}</Typography>
              <div className={styles.ping_statistics}>
                <div>
                  Pacotes
                </div>
                <div className={styles.ping_statistics_div}>
                  <div className={styles.ping_info}>
                    <span>{`Enviados: ${pingResponse?.transmitted_packets}`}</span>
                  </div>
                  <div className={styles.ping_min}>
                    <span>{`Min: ${pingResponse?.rtt_time.min}ms`}</span>
                  </div>
                </div>

                <div className={styles.ping_statistics_div}>
                  <div className={styles.ping_info}>
                    <span>{`Recebidos: ${pingResponse?.received_packets}`}</span>
                  </div>
                  <div className={styles.ping_max}>
                    <span>{`Max: ${pingResponse?.rtt_time.max}ms`}</span>
                  </div>
                </div>

                <div className={styles.ping_statistics_div}>
                  <div className={styles.ping_info}>
                    <span>{`Perdidos: ${pingResponse?.loss_packets}`}</span>
                  </div>
                  <div className={styles.ping_media}>
                    <span>{`Média: ${pingResponse?.rtt_time.avg}ms`}</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </Box >
      </Modal>
    </>
  );
};

export default IpLightCard;