import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';
import IatResultDetail from '../../components/IatResultDetail';
import { IatConfigResultDetail } from '../../interfaces/IatConfigResultDetail';
import handleError from '../../utils/handleError';
import { lpRouterResultConfigRequest } from '../../services/apiSys';

type Props = {};

const IatHistoricDetail = (props: Props) => {

  const { taskId } = useParams();
  const [resultConfig, setResultConfig] = useState<IatConfigResultDetail>();
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState(false);

  const getResultConfigDetail = async (taskId: string) => {
    try {
      setLoading(true);
      const result = await lpRouterResultConfigRequest(taskId);
      if (result.data?.idVantive) {
        setResultConfig(result.data);
      } else {
        setWarning('Essa não é uma Task IAT.');
      }
    } catch (error) {
      handleError({ error, setError });
      setError('Falha durante a operação do IAT. Não há um relatório detalhado.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (taskId) getResultConfigDetail(taskId);
  }, [taskId]);

  return (
    <Box m={'1em'}>
      <Box display={'flex'} justifyContent={'center'}>
        <h1>Histórico de Atividade Detalhada</h1>
      </Box>
      {loading && (<Skeleton variant='rounded' width={'100%'} height={200} />)}
      {error && (<p className='error'>{error}</p>)}
      {warning && (<p className='warn'>{warning}</p>)}
      {resultConfig && (
        <IatResultDetail result={resultConfig} />
      )}
    </Box>
  );
};

export default IatHistoricDetail;