import { useEffect, useState } from 'react';
import { IVivo2Circuit } from '../../interfaces/IVivo2Circuit';
import Vivo2CircuitTable from '../../components/Vivo2CircuitTable';
import { Button, Box, Typography, Alert } from '@mui/material';
import Vivo2CircuitModal from '../../components/Vivo2CircuitModal';
import handleError from '../../utils/handleError';
import { addVivo2Installation, deleteVivo2Installation, editVivo2Installation, getVivo2Installation } from '../../services/apiWeb';
import ConfirmationDialog from '../../components/ConfirmationDialog';

type Props = {};

const CrudVivo2 = (props: Props) => {
  const [installations, setInstallations] = useState<IVivo2Circuit[]>([]);
  const [editingInstallation, setEditingInstallation] = useState<IVivo2Circuit>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [scheduleToDelete, setScheduleToDelete] = useState<string | null>(null);


  useEffect(() => {
    getVivo2Installations();
  }, []);

  const getVivo2Installations = async () => {
    try {
      const resp = await getVivo2Installation();
      setInstallations(resp.data ?? []);
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const addVivo2Installations = async (installation: IVivo2Circuit) => {
    try {
      const resp = await addVivo2Installation(installation);
      setSuccess('Instalação adicionada com sucesso.');
      if (resp.data) {
        setInstallations([...installations, { ...resp.data }]);
      }
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const editVivo2Installations = async (installation: IVivo2Circuit) => {
    try {
      const { id, ...data } = installation;
      await editVivo2Installation(id!, data);
      setInstallations(installations.map(s => (s.id === installation.id ? installation : s)));
      setEditingInstallation(undefined);
      setSuccess('Instalação editada com sucesso.');
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const deleteVivo2Installations = async (id: string) => {
    try {
      const resp = await deleteVivo2Installation(id);
      setInstallations(installations.filter(i => i.id !== scheduleToDelete));
      setSuccess('Instalação deletada com sucesso.');
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const handleSaveInstallation = (installation: IVivo2Circuit) => {
    if (editingInstallation) {
      editVivo2Installations(installation);
    } else {
      addVivo2Installations(installation);
    }
    setIsModalOpen(false);
  };

  const handleEditInstallation = (installation: IVivo2Circuit) => {
    setEditingInstallation(installation);
    setIsModalOpen(true);
  };

  const handleDeleteInstallation = (id: string) => {
    setScheduleToDelete(id);
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDeleteInstallation = (confirmed: boolean) => {
    if (confirmed && scheduleToDelete) {
      deleteVivo2Installations(scheduleToDelete);
    }
    setScheduleToDelete(null);
    setIsConfirmDialogOpen(false);
  };

  const handleOpenModal = () => {
    setEditingInstallation(undefined);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box m={2}>
      <Typography variant='h3' align='center'>Gerenciar Circuitos</Typography>
      {error && (
        <Alert severity="error" variant='filled' onClose={() => { setError(''); }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" variant='filled' onClose={() => { setSuccess(''); }}>
          {success}
        </Alert>
      )}
      <Box marginY={2}>
        <Button onClick={handleOpenModal} variant='contained' className='button'  >Adicionar novo circuito</Button>
      </Box>
      <Vivo2CircuitTable installations={installations} onEdit={handleEditInstallation} onDelete={handleDeleteInstallation} />
      <Vivo2CircuitModal open={isModalOpen} onClose={handleCloseModal} onSave={handleSaveInstallation} installation={editingInstallation} />
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={handleConfirmDeleteInstallation}
        content='Deseja deletar este circuito cadastrado?'
      />
    </Box>
  );
};

export default CrudVivo2;