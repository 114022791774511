import { GridApi, GridRowSelectionModel } from "@mui/x-data-grid";

export const safeJsonParse = <T,>(str: string): T | undefined => {
  try {
    const jsonValue: T = JSON.parse(str);
    return jsonValue;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return undefined;
  }
};

// Datagrid  function to checkbox select all only seelct the visible itens on active page
export const checkboxSelectionVisibleOnly = ({
  apiRef,
  rowSelectionModel,
}: {
  apiRef: GridApi;
  rowSelectionModel: GridRowSelectionModel;
}): GridRowSelectionModel => {

  const {
    state: {
      rowSelection,
      pagination: {
        paginationModel: { page, pageSize },
      }
    },
  } = apiRef;

  // no selection yet, select rows from current page
  if (rowSelection.length === 0) {
    // user clicked on "All" checkbox
    if (rowSelectionModel.length > 1) {
      const startIndex = page * pageSize;
      const endIndex = startIndex + pageSize;
      return apiRef.getSortedRowIds().slice(startIndex, endIndex);
    } else {
      // user clicked on specific row
      return rowSelectionModel;
    }
  }

  // user unchecked row(s)
  if (rowSelectionModel.length < rowSelection.length) {
    return rowSelectionModel;
  }

  return rowSelectionModel;
};
