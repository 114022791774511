export enum Roles {
  admin = 'b2b-web-admin',
  suporte = 'b2b-web-suporte',
  tecnico = 'b2b-web-tecnico',
  avancado = 'b2b-web-avancado',
}

export enum HpsdCategory {
  'ConfiguracaoCPE' = 'Configuração de CPE',
  'ManobraIPs.MNIP06' = 'Execução Manobra de IPs (MN06)',
  'ManobraRecursos.MN05' = 'Validação de campos para manobra de recursos (MN05)',
  'ManobraRecursos.MN07' = 'Obtenção de campos disponíveis para manobra de recursos (MN07)',
  'ManobraRecursos.MN14' = 'Execução Manobra de Recursos (MN14)',
  'Desconexao' = 'Desconfiguração de Circuito',
  'Desalocacao' = 'Desalocação de IPs',
  'Alocacao' = 'Alocação de IPs',
  'Configuracao' = 'Configuração de Circuito',
  'ManobraRecursos.MNVL05' = 'Validação de VLAN',
  'ManobraRecursos.MNVL14' = 'Execução de Manobra VLAN',
}

export enum UnidadeVelocidade {
  'Kilobits' = 'Kbps',
  'Megabits' = 'Mbps',
  'Gigabits' = 'Gbps',
}

export enum IdStatusResult {
  'Sucesso' = 1,
  'Falha' = 2,
  'Alerta' = 3,
  'Ignorado' = 0,
  'Erro' = -1,
}

export enum Vivo2Category {
  'pre_router' = 'Pré Router',
}

export enum VlanType {
  CVLAN = 'CVLAN (Cliente)',
  SVLAN = 'SVLAN (Serviço)',
}