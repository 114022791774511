import { useEffect, useState } from "react"

const useDebugMode = () => {
    const [debugMode, setDebugMode] = useState(false)

    useEffect(() => {
        localStorage.setItem("debugMode", debugMode.toString())
    }, [debugMode])

    return {debugMode, setDebugMode}
}

export default useDebugMode