import { Box, Card, CardContent, CardHeader, Divider, Grid, Skeleton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Event, Service } from '../../interfaces/HpsdConfigCallback';
import { useParams } from 'react-router-dom';
import { hpsdConfigCallbackRequest } from '../../services/apiSys';
import handleError from '../../utils/handleError';
import ArticleIcon from '@mui/icons-material/Article';
import BuildIcon from '@mui/icons-material/Build';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskIcon from '@mui/icons-material/Task';
import { HpsdCategory } from '../../utils/EnumUtils';
import HpsdResultDetail from '../../components/HpsdResultDetail';

const FenixHistoricDetail = () => {

  const { taskId } = useParams();
  const [genericDetail, setGenericDetail] = useState<Event>();
  const [resultDetail, setResultDetail] = useState<Service>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");

  const getConfigDetail = async (taskId: string) => {
    try {
      setLoading(true);
      const result = await hpsdConfigCallbackRequest(taskId);
      if (result.data && result.data.event) {
        const service = result.data.event.serviceOrder.serviceOrderItem[0].service;
        if (service.serviceCharacteristic.some((char) => char.name === 'RESULTADO_TESTES')) {
          setResultDetail(service);
        } else {
          setGenericDetail(result.data.event);
        }
      } else {
        setWarning('Essa não é uma Task Fenix.');
      }
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (taskId) getConfigDetail(taskId);
  }, [taskId]);


  return (
    <Box m={1}>
      <h1 style={{ textAlign: 'center' }}>Detalhamento da operação</h1>
      {loading && (<Skeleton variant='rounded' width={'100%'} height={250} />)}
      {error && (<p className='error'>{error}</p>)}
      {warning && (<p className='warn'>{warning}</p>)}
      {genericDetail && (
        <>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-around"
            alignItems="center">
            <Grid item xs={12}>
              <Card className='card' sx={{ display: 'flex' }}>
                {/* <CardHeader title='Informações da requisição' /> */}
                <CardContent sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-around',
                  '& .MuiDivider-root': { marginRight: 1 }
                }}>
                  <div>
                    <Typography variant='h6'>ID Configuração</Typography>
                    <Typography>{taskId}</Typography>
                  </div>
                  <Divider orientation='vertical' />
                  <div>
                    <Typography variant='h6'>Designador</Typography>
                    <Typography>{genericDetail.serviceOrder.relatedParty[0].id}</Typography>
                  </div>
                  <Divider orientation='vertical' />
                  <div>
                    <Typography variant='h6'>Operação</Typography>
                    <Typography>{HpsdCategory[genericDetail.serviceOrder.serviceOrderItem[0].service.category as keyof typeof HpsdCategory]}</Typography>
                  </div>
                  <Divider orientation='vertical' />
                  <div>
                    <Typography variant='h6'>Status</Typography>
                    <Typography >{genericDetail.serviceOrder.state}</Typography>
                  </div>
                  <Divider orientation='vertical' />
                  <div>
                    <Typography variant='h6'>Horário da Requisição</Typography>
                    <Typography >{new Date(genericDetail.serviceOrder.requestedStartDate).toLocaleString("pt-BR", { timeZone: 'UTC' })}</Typography>
                  </div>
                  <Divider orientation='vertical' />
                  <div>
                    <Typography variant='h6'>Horário da Finalização</Typography>
                    <Typography >{new Date(genericDetail.serviceOrder.completionDate).toLocaleString("pt-BR", { timeZone: 'UTC' })}</Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className='card'>
                <CardHeader title='Informações da operação' sx={{ textAlign: 'center' }} />
                <CardContent>
                  <Box display={'flex'} flexDirection={'column'}>
                    {/* Show Error Description */}
                    {genericDetail.serviceOrder.description && (
                      <TextField
                        label={`Descrição do Erro`}
                        value={genericDetail.serviceOrder.description}
                        fullWidth
                        InputProps={{
                          readOnly: true
                        }}
                        margin='dense'
                      />
                    )}
                    {/* All Service Characteristic fields mapped */}
                    {genericDetail.serviceOrder.serviceOrderItem[0].service.serviceCharacteristic.map((service, index) => (
                      <TextField
                        key={index}
                        label={service.name}
                        value={service.value}
                        fullWidth
                        InputProps={{
                          readOnly: true
                        }}
                        margin='dense'
                      />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid >
        </>
      )}
      {resultDetail && (
        <HpsdResultDetail service={resultDetail} />
      )}
    </Box >
  );
};

export default FenixHistoricDetail;