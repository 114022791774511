import { Card, CardContent } from '@mui/material';
import React from 'react';

type Props = {};

const NotFound = (props: Props) => {
  return (
    <div>
      <Card>
        <CardContent>
          <h1>Página não encontrada.</h1>
        </CardContent>
      </Card>
    </div>
  );
};

export default NotFound;