import { Switch } from "@mui/material"
import useDebugMode from "../hooks/useDebugMode";

const DebugSwitch = () => {

    const {setDebugMode} = useDebugMode()
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setDebugMode(checked)
    }

    if(process.env.REACT_APP_ENVIRONMENT !== "development") return <></>
    
    return <>
        <Switch
        inputProps={{ 'aria-label': 'controlled' }}
        onChange={handleChange}
        />
        <span style={{marginRight: "1em"}}>Debug</span>
    </>
}

export default DebugSwitch