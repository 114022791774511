import { IatInfoData } from "../interfaces/IatInfoData";
import { IatConfigResultDetail } from "../interfaces/IatConfigResultDetail";
import request from "../utils/requestUtil";
import { ApiResponse } from "../interfaces/ApiResponse";
import { IatConfigResponse } from "../interfaces/IatConfigResponse";
import { IatHistoricConfig } from "../interfaces/IatHistoricConfig";
import { IatTracking } from "../interfaces/IatTracking";
import { InternetFacility } from "../interfaces/InternetFacility";
import { HpsdConfigBodyRequest } from "../interfaces/HpsdConfigBodyRequest";
import { HpsdTracking, Task } from "../interfaces/HpsdTracking";
import { HpsdTaskTrackingUpdate } from "../interfaces/HpsdTaskTrackingUpdate";
import { Order } from "../interfaces/Order";
import { IatConfigUpdate } from "../interfaces/IatConfigUpdate";
import { HpsdConfigCallback } from "../interfaces/HpsdConfigCallback";
import { HpsdConfigResponse } from "../interfaces/HpsdConfigResponse";
import { HpsdCircuitReworkBackup } from "../interfaces/HpsdCircuitReworkBackup";
import { IVivo2ResultDetail } from "../interfaces/IVivo2ResultDetail";

const API_SYS_BASE_URL: string = process.env.REACT_APP_API_SYS_BASE_URL ?? 'http://127.0.0.1:8000/api/sys/v1';

async function requestApiSys(url: string, method: string = 'GET', data: any = null, headers: any = {}): Promise<any> {
  const defaultHeaders = {
    'X-TimeZone-Offset': 'UTC-03:00',
    ...headers,
  };
  return request(API_SYS_BASE_URL, url, method, data, defaultHeaders);
}

// ----------- IAT Routes ---------------

export async function lpSearchRequest(id: string, searchStar?: boolean): Promise<ApiResponse<IatInfoData>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/${id}`, 'GET');
  return await requestApiSys(`/iat/lp/${id}?searchDataStar=${searchStar ?? false}&create=false`, 'GET');
}

export async function lpConfigRequest(body: IatInfoData): Promise<ApiResponse<IatConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/config`, 'POST', body);
  return await requestApiSys(`/iat/lp/config`, 'POST', body);
}

export async function lpResultConfigRequest(taskId: number): Promise<ApiResponse<IatConfigResultDetail>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/result/${taskId}`, 'GET');
  return await requestApiSys(`/iat/lp/result/${taskId}`, 'GET');
}

export async function lpCertificationRequest(body: IatInfoData): Promise<ApiResponse<IatConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/config`, 'POST', body);
  return await requestApiSys(`/iat/lp/check-config/check`, 'POST', body);
}

// export async function lpResultCertificationRequest(taskId: number): Promise<ApiResponse<IatConfigResultDetail>> {
//   // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/check-config/${taskId}`, 'GET');
//   return await requestApiSys(`/iat/lp/check-config/${taskId}`, 'GET');
// }

export async function lpRouterSearchRequest(id: string, searchStar?: boolean): Promise<ApiResponse<IatInfoData>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/${id}`, 'GET');
  return await requestApiSys(`/iat/lp/router/${id}?searchDataStar=${searchStar ?? false}&create=false`, 'GET');
}

export async function lpRouterConfigRequest(body: IatInfoData): Promise<ApiResponse<IatConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/config`, 'POST', body);
  return await requestApiSys(`/iat/lp/router/config`, 'POST', body);
}

export async function lpRouterResultConfigRequest(taskId: string): Promise<ApiResponse<IatConfigResultDetail>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/result/${taskId}`, 'GET');
  return await requestApiSys(`/iat/lp/router/result/${taskId}`, 'GET');
}

export async function lpRouterCertificationRequest(body: IatInfoData): Promise<ApiResponse<IatConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/config`, 'POST', body);
  return await requestApiSys(`/iat/lp/router/check-config/check`, 'POST', body);
}

// export async function lpRouterResultCertificationRequest(taskId: number): Promise<ApiResponse<IatConfigResultDetail>> {
//   // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/check-config/${taskId}`, 'GET');
//   return await requestApiSys(`/iat/lp/router/check-config/${taskId}`, 'GET');
// }

export async function swtSearchRequest(id: string, searchStar?: boolean): Promise<ApiResponse<IatInfoData>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/${id}`, 'GET');
  return await requestApiSys(`/iat/swt/${id}?searchDataStar=${searchStar ?? false}&create=false`, 'GET');
}

export async function swtCheckRequest(body: IatInfoData): Promise<ApiResponse<IatConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/config`, 'POST', body);
  return await requestApiSys(`/iat/swt/check`, 'POST', body);
}

export async function swtResultCheckRequest(taskId: string): Promise<ApiResponse<IatConfigResultDetail>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/result/${taskId}`, 'GET');
  return await requestApiSys(`/iat/swt/result/${taskId}`, 'GET');
}

export async function oltSearchRequest(id: string, searchStar?: boolean): Promise<ApiResponse<IatInfoData>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/${id}`, 'GET');
  return await requestApiSys(`/iat/olt/${id}?searchDataStar=${searchStar ?? false}&create=false`, 'GET');
}

export async function oltCheckRequest(body: IatInfoData): Promise<ApiResponse<IatConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/config`, 'POST', body);
  return await requestApiSys(`/iat/olt/check`, 'POST', body);
}

export async function oltResultCheckRequest(taskId: string): Promise<ApiResponse<IatConfigResultDetail>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/result/${taskId}`, 'GET');
  return await requestApiSys(`/iat/olt/result/${taskId}`, 'GET');
}

export async function configurationSearchRequest(id: string, searchStar?: boolean): Promise<ApiResponse<IatInfoData>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/${id}`, 'GET');
  return await requestApiSys(`/iat/configuration/${id}?searchDataStar=${searchStar ?? false}&create=false`, 'GET');
}

export async function configurationCheckRequest(body: IatInfoData): Promise<ApiResponse<IatConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/configuration/config`, 'POST', body);
  return await requestApiSys(`/iat/configuration/check`, 'POST', body);
}

export async function configurationResultCheckRequest(taskId: string): Promise<ApiResponse<IatConfigResultDetail>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/configuration/result/${taskId}`, 'GET');
  return await requestApiSys(`/iat/configuration/result/${taskId}`, 'GET');
}

export async function programmingSearchRequest(id: string, searchStar?: boolean): Promise<ApiResponse<IatInfoData>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/${id}`, 'GET');
  return await requestApiSys(`/iat/programming/${id}?searchDataStar=${searchStar ?? false}&create=false`, 'GET');
}

export async function programmingCheckRequest(body: IatInfoData): Promise<ApiResponse<IatConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/config`, 'POST', body);
  return await requestApiSys(`/iat/programming/check`, 'POST', body);
}

export async function programmingResultCheckRequest(taskId: string): Promise<ApiResponse<IatConfigResultDetail>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/result/${taskId}`, 'GET');
  return await requestApiSys(`/iat/programming/result/${taskId}`, 'GET');
}

export async function duplicitySearchRequest(id: string, searchStar?: boolean): Promise<ApiResponse<IatInfoData>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/${id}`, 'GET');
  return await requestApiSys(`/iat/duplicity/${id}?searchDataStar=${searchStar ?? false}&create=false`, 'GET');
}

export async function duplicityCheckRequest(body: IatInfoData): Promise<ApiResponse<IatConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/config`, 'POST', body);
  return await requestApiSys(`/iat/duplicity/check`, 'POST', body);
}

export async function duplicityResultCheckRequest(taskId: string): Promise<ApiResponse<IatConfigResultDetail>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/iat/lp/router/order/result/${taskId}`, 'GET');
  return await requestApiSys(`/iat/duplicity/result/${taskId}`, 'GET');
}

// -------- HPSD Routes ---------------------

export async function hpsdFacilitiesRequest(id: string): Promise<ApiResponse<InternetFacility>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/hpsd/tmf-638/facilities/${id}`, 'GET');
  return await requestApiSys(`/hpsd/tmf-638/facilities/${id}`, 'GET');
}

export async function hpsdConfigRequest(body: HpsdConfigBodyRequest): Promise<ApiResponse<HpsdConfigResponse>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/hpsd/tmf-641`, 'POST', body);
  return await requestApiSys(`/hpsd/tmf-641`, 'POST', body);
}

export async function hpsdConfigCallbackRequest(id: string): Promise<ApiResponse<HpsdConfigCallback>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/hpsd/tmf-641/${id}`, 'GET');
  return await requestApiSys(`/hpsd/result/${id}`, 'GET');
}

export async function hpsdRedesenhoResultRequest(id: string): Promise<ApiResponse<HpsdConfigCallback>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/hpsd/redesenho/result/${id}`, 'GET');
  return await requestApiSys(`/hpsd/redesenho/result/${id}`, 'GET');
}

export async function hpsdRedesenhoBackupRequest(idDesignador: string): Promise<ApiResponse<HpsdCircuitReworkBackup>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/hpsd/facility/bkp/${idDesignador}`, 'GET');
  return await requestApiSys(`/hpsd/redesenho/backup/${idDesignador}`, 'GET');
}

export async function hpsdRedesenhoTaskRequest(idDesignador: string): Promise<ApiResponse<Task>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/hpsd/redesenho/task/${idDesignador}`, 'GET');
  return await requestApiSys(`/hpsd/redesenho/task/${idDesignador}`, 'GET');
}

// ---------- Tracking Routes -------------------

export async function trackingHistoricIatRequest(params: { key: string; value: string | number; }[]): Promise<ApiResponse<IatHistoricConfig[]>> {
  // Build query parameters
  const queryParams = params.map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');

  // Append query parameters to the URL
  const uri = `/tracking/iat/historic${queryParams ? `?${queryParams}` : ''}`;

  // return await request(`http://localhost:8080`, `/api/sys/v1/tracking/historic`, 'GET');
  return await requestApiSys(uri, 'GET');
}

export async function trackingIatListRequest(idVantive: number): Promise<ApiResponse<IatTracking[]>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/tracking/iat/list/${idVantive}`, 'GET');
  return await requestApiSys(`/tracking/iat/list/${idVantive}`, 'GET');
}

export async function trackingIatUpdateRequest(body: IatConfigUpdate): Promise<ApiResponse<IatTracking>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/tracking/iat`, 'POST');
  return await requestApiSys(`/tracking/iat`, 'POST', body);
}

export async function trackingHistoricHpsdRequest(params: { key: string; value: string | number; }[]): Promise<ApiResponse<IatHistoricConfig[]>> {
  // Build query parameters
  const queryParams = params.map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');

  // Append query parameters to the URL
  const uri = `/tracking/hpsd/historic${queryParams ? `?${queryParams}` : ''}`;

  // return await request(`http://localhost:8080`, `/api/sys/v1` + uri, 'GET');
  return await requestApiSys(uri, 'GET');
}

export async function trackingHpsdListRequest(idDesignador: string): Promise<ApiResponse<HpsdTracking[]>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/tracking/hpsd/list/${idDesignador}`, 'GET');
  return await requestApiSys(`/tracking/hpsd/list/${idDesignador}`, 'GET');
}

export async function trackingHpsdUpdateRequest(body: HpsdTaskTrackingUpdate): Promise<ApiResponse<HpsdTracking>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/tracking/list/${idVantive}`, 'GET');
  return await requestApiSys(`/tracking/hpsd`, 'POST', body);
}

// ---------- Order Routes -----------------

export async function orderLoginRequest(loginTecnico: string): Promise<ApiResponse<Order>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/order?login_tecnico=${loginTecnico}`, 'GET');
  return await requestApiSys(`/order?login_tecnico=${loginTecnico}`, 'GET');
}

// ------------------------ Vivo 2 ----------------------------------

export async function trackingHistoricVivo2Request(params: { key: string; value: string | number; }[]): Promise<ApiResponse<IatHistoricConfig[]>> {
  // Build query parameters
  const queryParams = params.map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');

  // Append query parameters to the URL
  const uri = `/tracking/vivo2/historic${queryParams ? `?${queryParams}` : ''}`;

  // return await request(`http://localhost:8080`, `/api/sys/v1` + uri, 'GET');
  return await requestApiSys(uri, 'GET');
}

export async function vivo2ResultDetailRequest(taskId: string): Promise<ApiResponse<IVivo2ResultDetail>> {
  // return await request(`http://localhost:8080`, `/api/sys/v1/vivo2/result/${taskId}`, 'GET');
  return await requestApiSys(`/tracking/vivo2/results/${taskId}`, 'GET');
}
