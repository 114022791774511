import { Skeleton } from '@mui/material';
import styles from './Programming.module.css';
import { useEffect, useState } from 'react';
import IatLegacyDisplay from '../../components/IatLegacyDisplay';
import { IatInfoData } from '../../interfaces/IatInfoData';
import { programmingCheckRequest, programmingResultCheckRequest, programmingSearchRequest } from '../../services/apiSys';
import IatSearchIdVantive from '../../components/IatSearchIdVantive';
import { useParams } from 'react-router-dom';

const Programming = () => {
  const [equipInfo, setEquipInfo] = useState<IatInfoData>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { service } = useParams();
  const [searchStar, setSearchStar] = useState<boolean>();

  useEffect(() => {
    setSearchStar(service === 'star');
    setEquipInfo(undefined);
  }, [service]);

  return (
    <div className='main'>
      <h3 className={styles.title}>Diagnóstico de Programação</h3>
      <IatSearchIdVantive
        apiRequest={programmingSearchRequest}
        setEquipInfo={setEquipInfo}
        loading={{ isLoading: loading, setLoading }}
        setError={setError}
        searchStar={searchStar}
      />      <div className={styles.info_div}>
        {equipInfo && (
          <IatLegacyDisplay info={equipInfo} check={{ checkApi: programmingCheckRequest, resultCheckApi: programmingResultCheckRequest }} isStar={searchStar} />
        )}
        {loading && <Skeleton variant="rectangular" width='100%' height={500} />}
        {error && <p className='error'>{error}</p>}
      </div>
    </div>
  );
};

export default Programming;