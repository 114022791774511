import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UserProvider } from './contexts/UserContext';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './config/keycloak';
import { ptBR } from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import 'dayjs/locale/pt-br';


const theme = createTheme(
  ptBR,
);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <ReactKeycloakProvider authClient={keycloak} >
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br' >
              <App />
            </LocalizationProvider>
          </StyledEngineProvider>
        </UserProvider>
      </ThemeProvider>
    </React.StrictMode >
  </ReactKeycloakProvider>
);
