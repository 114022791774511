import { Box, Button, CircularProgress, Modal, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridToolbar, ptBR } from '@mui/x-data-grid';
import React, { FormEvent, useEffect, useState } from 'react';
import { KeycloakUser } from '../../interfaces/KeycloakAPI';
import handleError from '../../utils/handleError';
import { addUserGroupsRequest, deleteUserGroupsRequest, getAllGroupsRequest, getAllUsersRequest, getUserGroupsRequest } from '../../services/apiKeycloak';
import SelectAllTransferList from '../../components/SelectAllTransferList';
import EditIcon from '@mui/icons-material/Edit';
import { Item } from '../../interfaces/TransferList';


type Props = {};

const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '70vh',
};

// const users: readonly KeycloakUser[];
// const groups: readonly KeycloakGroup[];

const ManageUsers = (props: Props) => {
  const [users, setUsers] = useState<readonly KeycloakUser[]>([]);
  const [groups, setGroups] = useState<readonly Item[]>([]);
  const [userGroups, setUserGroups] = useState<Item[]>([]);
  const [selectedUser, setSelectedUser] = useState<KeycloakUser>();
  const [remainingGroups, setRemainingGroups] = useState<Item[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [error, setError] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const [username, setUsername] = useState<string>('');

  // const transferList: TransferList = {
  //   left: []
  //   right: { groups.map((group) => { id: group.id, label: group.name; }) }
  // };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    getAllUsers();
    getAllGroups();
  }, []);

  const getAllUsers = async (username?: string) => {
    try {
      setLoading(true);
      const resp = await getAllUsersRequest(username);
      setUsers(resp);
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  const getAllGroups = async () => {
    try {
      const resp = await getAllGroupsRequest();
      let groups = resp.map((group) => ({ id: group.id, label: group.name }));
      groups = groups.filter(group => !group.label.includes('admin'));
      setGroups(groups);
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const getUserGroups = async (userId: string) => {
    try {
      setLoadingModal(true);
      const resp = await getUserGroupsRequest(userId);
      setUserGroups(resp.map((group) => ({ id: group.id, label: group.name })));
      setRemainingGroups(groups.filter((group) => !resp.some(groupUser => groupUser.id === group.id)));
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoadingModal(false);
    }
  };

  const handleGroupClick = (user: KeycloakUser) => () => {
    setSelectedUser(user);
    getUserGroups(user.id);
  };

  /**
   * Open Modal to manage user roles
   */
  useEffect(() => {
    if (userGroups.length > 0 && remainingGroups.length > 0) {
      handleOpenModal();
    }
  }, [userGroups, remainingGroups]);

  const addGroups = async (userId: string, groups: Item[]) => {
    try {
      const updatePromise = groups.map((group) => addUserGroupsRequest(userId, group.id));
      await Promise.all(updatePromise);
    } catch (error) {
      throw error;
    }
  };

  const deleteGroups = async (userId: string, groups: Item[]) => {
    try {
      const deletePromise = groups.map((group) => { deleteUserGroupsRequest(userId, group.id); });
      const resp = await Promise.all(deletePromise);
    } catch (error) {
      throw error;
    }
  };


  const columns: GridColDef<KeycloakUser>[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'username', headerName: 'Username', flex: 1, hideable: false },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'firstName', headerName: 'Nome', flex: 1 },
    { field: 'lastName', headerName: 'Sobrenome', flex: 1 },
    {
      field: 'createdTimestamp',
      headerName: 'Criação',
      flex: 2,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(new Date(value).toUTCString().slice(0, -4)),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Gerenciar',
      getActions: ({ row }) => {

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Grupos'
            onClick={handleGroupClick(row)}
            className="textPrimary"
            color='inherit'
          />
        ];
      }
    }
  ];

  function handleSearchSpecificUser(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    getAllUsers(username);
  }

  return (
    <>
      <Box style={{ height: 500, width: '100%' }}>
        <h3>Gerenciar Usuários</h3>
        <Box
          component="form"
          onSubmit={handleSearchSpecificUser}
          display={'flex'}
          width={'100%'}
          marginBottom={1}
        >
          <TextField
            fullWidth
            value={username}
            label="Buscar Usuário"
            onChange={(e) => setUsername(e.target.value)}
            placeholder='username'
          />
          <Button
            className='button'
            sx={{ marginLeft: '1em' }}
            variant="contained"
            color="primary"
            disabled={loading}
            type='submit'
          >
            {loading ? 'Buscando...' : 'Buscar'}
          </Button>
        </Box>
        <DataGrid
          rows={users}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
            sorting: {
              sortModel: [{ field: 'creation_date', sort: 'desc' }]
            }
          }}
          loading={loading}
          pageSizeOptions={[15, 30, 50]}
          slots={{
            toolbar: GridToolbar,
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box sx={styleModal} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          {userGroups && remainingGroups.length > 0 && selectedUser && (
            <>
              <h3>{`Usuário: ${selectedUser.username}`}</h3>
              <SelectAllTransferList
                transferList={{ left: userGroups, right: remainingGroups, leftLabel: 'Grupos Associados', rightLabel: 'Grupos não Associados' }}
                functionMoveLeft={{ function: addGroups, parameters: { userId: selectedUser.id } }}
                functionMoveRigth={{ function: deleteGroups, parameters: { userId: selectedUser.id } }}
              />
            </>
          )}
          {loadingModal && (
            <CircularProgress size={100} />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ManageUsers;