import { Skeleton } from '@mui/material';
import styles from './LpRouter.module.css';
import { useEffect, useState } from 'react';
import IatLegacyDisplay from '../../components/IatLegacyDisplay';
import { IatInfoData } from '../../interfaces/IatInfoData';
import { lpRouterCertificationRequest, lpRouterConfigRequest, lpRouterResultConfigRequest, lpRouterSearchRequest } from '../../services/apiSys';
import IatSearchIdVantive from '../../components/IatSearchIdVantive';
import { useParams } from 'react-router-dom';

const LpRouter = () => {
  const { service } = useParams();
  const [equipInfo, setEquipInfo] = useState<IatInfoData>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchStar, setSearchStar] = useState<boolean>();

  useEffect(() => {
    setSearchStar(service === 'star');
    setEquipInfo(undefined);
  }, [service]);

  return (
    <div className='main'>
      <h3 className={styles.title}>Ativação LP+Router</h3>
      <IatSearchIdVantive
        apiRequest={lpRouterSearchRequest}
        setEquipInfo={setEquipInfo}
        loading={{ isLoading: loading, setLoading }}
        setError={setError}
        searchStar={searchStar}
      />
      <div className={styles.info_div}>
        {equipInfo && (
          <IatLegacyDisplay info={equipInfo} config={{ configApi: lpRouterConfigRequest, resultConfigApi: lpRouterResultConfigRequest, certificationTask: { certificationApi: lpRouterCertificationRequest, resultCertificationApi: lpRouterResultConfigRequest } }} isStar={searchStar} />
        )}
        {loading && <Skeleton variant="rounded" width='100%' height={500} />}
        {error && <p className='error'>{error}</p>}
      </div>
    </div>
  );
};

export default LpRouter;