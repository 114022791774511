import styles from './IatResultDetail.module.css';
import { Box, Card, Dialog, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import saveAs from 'file-saver';
//Icons
import InfoIcon from '@mui/icons-material/Info';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
//Interfaces
import { IatConfigResultDetail, NetworkCommandResult } from '../interfaces/IatConfigResultDetail';
//Assets
//Components
import StatusIcon from './StatusIcon';
import AssignmentLate from '@mui/icons-material/AssignmentLate';
import ManualTask from './ManualTask';

type Props = {
  result: IatConfigResultDetail;
  handleReconfig?: () => Promise<void>;
  manualTask?: { taskType: 'config' | 'certification'; taskProduct: string; };
};

enum IatStatus {
  "Teste Ok" = 1,
  "Teste Falhou" = 2,
  "Teste Warning" = 3,
  "Não Executado" = 0,
  "Erro" = -1,
}

type LogInfo = {
  idVantive: number;
  idExecution?: number;
  procedure: string;
  status?: number;
  body: string;
};

type ModalProps = {
  open: boolean;
  onClose: () => void;
  logInfo: LogInfo;
};

/**
 * IAT Legacy Component for display the product's results of the operation (configuration or certification).
 * @param result The object returned from IAT Legacy Config APIs
 * @param handleReconfig The method allowing the re-execute the operation for this product
 * @param manualTask Object containing the taskType (config or certification) and taskProduct to enable the user to execute a manual task
 * @returns 
 */
const IatResultDetail = ({ result, handleReconfig, manualTask }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedLogInfo, setSelectedLogInfo] = useState<LogInfo>({ idVantive: result.idVantive, idExecution: result.idExecution, procedure: "Log Completo", body: result.completeLogs });

  const handleOpenModal = (logInfo: LogInfo) => {
    setSelectedLogInfo(logInfo);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const Header = () => {
    return (
      <div className={styles.header}>
        <Card className={styles.card_header}>
          <div className={styles.card_header_content}>
            {/* <img src={taskIcon} alt="task " /> */}
            <ArticleIcon
              color='primary'
              // fontSize='large'
              sx={{ fontSize: '3em' }}
            />
            <div>
              <Typography variant='h6' >Task</Typography>
              <Typography marginRight={1}>{result.productType.productType}</Typography>
            </div>
          </div>
        </Card>
        <Card className={styles.card_header} style={{ marginRight: 'auto' }}>
          <div className={styles.card_header_content}>
            {/* <img src={resultIcon} alt="certificate " /> */}
            {result.idStatusResult === 1 ? (
              <AssignmentTurnedInIcon
                color='primary'
                sx={{ fontSize: '3em' }}
              />
            ) : (
              <AssignmentLate
                color='primary'
                sx={{ fontSize: '3em' }}
              />
            )}
            <div>
              <Typography variant='h6' >Resultado</Typography>
              <Typography marginRight={1}>{result.messageResult}</Typography>
            </div>
          </div>
        </Card>
        <IconButton
          className={styles.button_header}
          onClick={() => handleOpenModal({ ...selectedLogInfo, procedure: "Log Completo", body: result.completeLogs, status: undefined })}>
          <Tooltip title="Log Completo">
            {/* <img src={logIcon} alt="completeLogs" /> */}
            <FeedOutlinedIcon
              // style={{ color: '#6C389D80' }} 
              color='info'
              fontSize='large'
            />
          </Tooltip>
        </IconButton>
        {handleReconfig && (
          <IconButton
            className={styles.button_header}
            onClick={() => handleReconfig()}>
            <Tooltip title="Reconfigurar">
              {/* <img src={logIcon} alt="completeLogs" /> */}
              <RestartAltIcon
                // style={{ color: '#6C389D80' }}
                color='action'
                fontSize='large'
              />
            </Tooltip>
          </IconButton>
        )}
      </div>
    );
  };

  const ResultTable = ({ title, netCommands, headerColor }: { title: string; netCommands: NetworkCommandResult[], headerColor: string; }) => (
    <TableContainer component={Paper} className={styles.result_table}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4} style={{ background: headerColor }}>
              <Typography variant="h6">
                {title}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={styles.column_header}>
            <TableCell>Status</TableCell>
            <TableCell align="left">Procedimento</TableCell>
            <TableCell align="left">Resultado</TableCell>
            <TableCell align="right">Log</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {netCommands && netCommands.map((command) => (
            <TableRow
              key={command.step}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <StatusIcon iatStatusId={command.idStatus} />
              </TableCell>
              <TableCell align="left">{command.procedureDescription}</TableCell>
              <TableCell align="left">{command.result}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => handleOpenModal(
                  { ...selectedLogInfo, procedure: command.procedureDescription, body: command.log, status: command.idStatus }
                )}
                >
                  <InfoIcon
                    //  style={{ color: '#6C389D80' }} 
                    color='info'
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box>
      <Header />
      {result.idStatusResult !== 1 && manualTask && (
        <ManualTask
          iat={{
            idVantive: result.idVantive,
            taskProduct: manualTask.taskProduct,
            productType: result.productType,
            taskType: manualTask.taskType
          }}
        />
      )}
      {result.results && result.results.map((res, index) => (
        <ResultTable
          key={index}
          title={res.headerDescription}
          netCommands={res.networkCommandResults}
          headerColor={!res.idStatusResult ? 'darkgray' : res.idStatusResult === 1 ? '#65B878' : '#F06D9E'} />
      ))}
      <CustomModal open={isModalOpen} onClose={handleCloseModal} logInfo={selectedLogInfo} />
    </Box>
  );
};

const CustomModal = ({ open, onClose, logInfo }: ModalProps) => {
  const statusDescription = logInfo.status !== undefined ? IatStatus[logInfo.status] : '';

  const handleDownloadTxt = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    const blob = new Blob([logInfo.procedure + `\n\n`, logInfo.body], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${logInfo.idVantive}-${logInfo.procedure}-log-${formattedDate}.txt`);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div>
        <IconButton onClick={handleDownloadTxt}>
          <Tooltip title="Salvar txt">
            <SaveIcon color='action' />
          </Tooltip>
        </IconButton>
      </div>
      <DialogTitle>
        {`ID Vantive: ${logInfo.idVantive} ID Execução: ${logInfo.idExecution} - ${logInfo.procedure} ${logInfo.status !== undefined ? (`- ${statusDescription}`) : ('')}`}
      </DialogTitle>
      <DialogContent>
        <pre>{logInfo.body}</pre>
      </DialogContent>
    </Dialog>
  );
};

export default IatResultDetail;