import { DataGrid, GridColDef, GridToolbar, ptBR } from '@mui/x-data-grid';
import StatusIcon from './StatusIcon';
import { Link } from 'react-router-dom';
import { IatHistoricConfig } from '../interfaces/IatHistoricConfig';
import { Box } from '@mui/material';
import { Vivo2Category } from '../utils/EnumUtils';

type Props = {
  data: IatHistoricConfig[],
};

/**
 * Component to show the historic of all operations executed to a designador
 * @param {IatHistoricConfig[]} data The HPSD Historic list info
 * @returns 
 */
const Vivo2HistoricActivities = ({ data }: Props) => {

  const columns: GridColDef<IatHistoricConfig>[] = [
    {
      field: 'id_result',
      headerName: 'ID do Resultado',
      flex: 2,
      renderCell: ({ value }) => (
        <Link to={`/vivo2/historic/${value}`} target="_blank" >{value}</Link>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Data de execução',
      flex: 2,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(new Date(value).toUTCString().slice(0, -4))
      // .toLocaleString("pt-BR", { timeZone: 'UTC' })
    },
    { field: 'username', headerName: 'Usuário', flex: 1 },
    { field: 'source', headerName: 'Sistema', flex: 1 },
    { field: 'action', headerName: 'Tipo de Ação', flex: 1, type: 'singleSelect', valueOptions: ['API', 'LOCAL'] },
    { field: 'product', headerName: 'Serviço', flex: 1, type: 'string' },
    {
      field: 'taskType',
      headerName: 'Operação',
      flex: 2,
      type: 'singleSelect',
      valueOptions: Object.values(Vivo2Category),
      valueGetter: ({ value }) => Vivo2Category[value as keyof typeof Vivo2Category]
    },
    { field: 'status', headerName: 'Status', flex: 1 },
  ];

  return (
    <Box marginTop={2} width={'100%'}>
      {data && (
        <DataGrid
          autoHeight
          rows={data}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: 'created_at', sort: 'desc' }]
            }
          }}
          pageSizeOptions={[10, 20, 30]}
          disableColumnSelector
          getRowId={(row) => row.id_result}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          sx={{ '--DataGrid-overlayHeight': '10em' }}
        />
      )}
    </Box>
  );
};

export default Vivo2HistoricActivities;