import { useUserContext } from '../contexts/UserContext';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { CircularProgress, Skeleton } from '@mui/material';

type Props = {
  allowedRoles?: string[];
};

const ProtectedRoute = ({ allowedRoles }: Props) => {
  const { userHasRole } = useUserContext();
  const location = useLocation();

  if (allowedRoles) {
    return (
      userHasRole(allowedRoles)
        ? <Outlet />
        : <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
  } else {
    return (
      <Outlet />
    );
  }
};

export default ProtectedRoute;