import { ReactNode, useState } from 'react';
import { Paper, Typography, Box, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type Props = {
  title: string,
  children: ReactNode;
};

const FormGroups = ({ title, children }: Props) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }} >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <IconButton onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box>
          {children}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default FormGroups;