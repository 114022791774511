import ApiError from './ApiError';
import { ApiSysError } from '../interfaces/ApiSysError';

type Props = {
  error: any,
  setError: (message: string) => void;
};

function handleError({ error, setError }: Props) {
  if (error instanceof ApiError) {
    if (error.status === 404) {
      if ((error.responseData as ApiSysError).error.cause[0].detail) {
        setError((error.responseData as ApiSysError).error.cause[0].detail);
      } else {
        setError("Resultado não encontrado.");
      }
    } else if (error.status === 403) {
      setError("Acesso Negado");
    } else if (error.status === 408) {
      setError("Timeout na resposta do sistema.");
    } else if (error.status === 400
      && (error.responseData as ApiSysError)
      && (error.responseData as ApiSysError).error
      && (error.responseData as ApiSysError).error.cause
      && (error.responseData as ApiSysError).error.cause[0].detail) {
      setError((error.responseData as ApiSysError).error.cause[0].detail);
    } else if (error.status === 502
      && (error.responseData as ApiSysError)
      && (error.responseData as ApiSysError).error
      && (error.responseData as ApiSysError).error.cause
      && (error.responseData as ApiSysError).error.cause[0].detail) {
      setError((error.responseData as ApiSysError).error.cause[0].detail);
    } else if (error.status === 503
      && (error.responseData as ApiSysError)
      && (error.responseData as ApiSysError).error
      && (error.responseData as ApiSysError).error.cause
      && (error.responseData as ApiSysError).error.cause[0].detail) {
      setError((error.responseData as ApiSysError).error.cause[0].detail);
    } else {
      setError("Erro inesperado. Tente novamente mais tarde.");
    }
  } else {
    if (error.message) {
      setError(error.message);
    } else {
      setError("Erro inesperado. Tente novamente mais tarde.");
    }
  }
}

export default handleError;