import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Modal, Box, Typography, Alert, Divider } from '@mui/material';
import { trackingIatUpdateRequest } from '../services/apiSys';
import CircularWithValueLabel from './CircularProgressWithLabel';
import StatusIcon from './StatusIcon';
import { IatTracking } from '../interfaces/IatTracking';
import { IatInfoData } from '../interfaces/IatInfoData';
import { IatConfigResultDetail } from '../interfaces/IatConfigResultDetail';
import IatResultDetail from './IatResultDetail';
import handleError from '../utils/handleError';
import { IatConfigUpdate } from '../interfaces/IatConfigUpdate';
import { IatTaskConfigCertification } from '../interfaces/IatTaskConfig';
import ManualTask from './ManualTask';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  iatInfo: IatInfoData;
  product: string;
  certification: IatTaskConfigCertification;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '70vh',
};

/**
 * Component to generate a taskbar and a button to open a Modal to certificate and finish an open task
 * @param open Boolean for the open state of the Finish Task Modal.
 * @param setOpen Method to change the open state of the Finish Task Modal.
 * @param iatInfo The Iat Info data retured from IAT API.
 * @param product The product relating to this task. ex: LP/LP+Router/SWT. 
 * @param certification The certification API to be used.
 * @returns 
 */
export default function IatFinishTaskSnackbar({ open, setOpen, iatInfo, product, certification }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [finishResult, setFinishResult] = useState<IatTracking>();
  const [certificationTaskId, setCertificationTaskId] = useState<string>();
  const [certificationResult, setCertificationResult] = useState<IatConfigResultDetail>();
  const [error, setError] = useState<string>();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCertification = async () => {
    try {
      handleOpenModal();
      const resp = await certification.certificationApi(iatInfo);
      if (resp.data && resp.data.id_result) {
        setCertificationTaskId(resp.data.id_result);
      }

    } catch (error) {
      console.log(error);
      handleError({ error, setError });
    }
  };

  const handleFinishTask = async (certificationResponse: IatConfigResultDetail, statusDescription: string) => {
    try {
      const body: IatConfigUpdate = {
        id_vantive: certificationResponse.idVantive,
        product: product,
        status: statusDescription, // 'COMPLETED',
      };
      const resp = await trackingIatUpdateRequest(body);
      if (resp.data) setFinishResult(resp.data);
    } catch (error) {
      console.log(error);
      handleError({ error, setError });
    }
  };

  //Ping for config task status
  useEffect(() => {
    if (certificationTaskId) {
      const pingCertificationTaskStatus = async () => {
        try {
          // Ping the API to check if the task is finished
          const response = await certification.resultCertificationApi(certificationTaskId!);

          if (response.data && Object.keys(response.data).length > 0) {
            // If the task is finished, clear the interval
            clearInterval(intervalId);
            // Handle the results data
            // console.log('Task results:', resultsResponse);
            setCertificationResult(response.data);
            if (response.data.idStatusResult === 1) {
              handleFinishTask(response.data, 'COMPLETED');
            }
            // setLoading(false);
          }
        } catch (error) {
          handleError({ error, setError });
          clearInterval(intervalId);
          // setLoading(false);
        }
      };

      // Check task status initially
      pingCertificationTaskStatus();
      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingCertificationTaskStatus();
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [certificationTaskId]);

  const action = (
    <>
      <Button color="primary" size="small" onClick={handleCertification}>
        Certificar Ordem
      </Button>
      {/* <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton> */}
    </>
  );

  return (
    <div>
      <Button className='button' onClick={handleCertification}>Certificar Ordem</Button>
      <Snackbar
        sx={{ zIndex: 1302 }}
        // anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} action={action} severity='info' sx={{ width: '100%' }}>{`Certificar e finalizar atividade`}</Alert>
      </Snackbar>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box sx={style} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <h3>Certificando e Finalizando a Ordem</h3>
          {/* <Box display={'flex'} justifyContent={'center'}> */}
          {error ? (<p className='error'>{error}</p>)
            : certificationResult ? (
              <>
                {certificationResult.idStatusResult != 1 && (
                  <ManualTask
                    iat={{
                      idVantive: iatInfo.idVantive,
                      productType: certificationResult ? certificationResult.productType : { idProductType: iatInfo.idServico, productType: iatInfo.service.name },
                      taskProduct: product,
                      taskType: 'certification',
                    }}
                  />
                )}
                {finishResult && (
                  <Typography >
                    <StatusIcon status={finishResult.status === 'COMPLETED'} text={['Ordem Finalizada', 'Ordem não finalizada']} />
                  </Typography>
                )}
                <Divider flexItem />
                <h4>Resultado da Certificação</h4>
                <IatResultDetail result={certificationResult} />
              </>
            ) : (
              <CircularWithValueLabel maxSecondsTime={30} interval={5000} />
            )}
          {/* </Box> */}
        </Box>
      </Modal>
    </div >
  );
}
