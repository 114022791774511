import React from 'react';
import { IatTracking } from '../interfaces/IatTracking';
import { Card, CardContent, Typography, Divider, Stepper, Step, StepLabel } from '@mui/material';
import { Box } from '@mui/system';
import StatusIcon from './StatusIcon';
import { HpsdTracking } from '../interfaces/HpsdTracking';
import { Link } from 'react-router-dom';

type Props = {
  tracking: IatTracking | HpsdTracking;
};

/**
 * Component to display the Task Tracking information, as the actual status and the tasks executed.
 * @param tracking  The collection TaskTracking info returned from API
 * @returns 
 */
const TaskTrackingDetailCard = ({ tracking }: Props) => {
  const formattedCreatedat = new Date(tracking.created_at).toLocaleString("pt-BR", { timeZone: 'UTC' });
  const formattedUpdatedat = new Date(tracking.updated_at).toLocaleString("pt-BR", { timeZone: 'UTC' });

  return (
    <>
      <h3>Tracking Order</h3>
      <Card className='card' sx={{ backgroundColor: 'whitesmoke' }}>
        <CardContent>
          <Box display={'flex'}>
            <div>
              <Typography sx={{ fontSize: 14 }} color={"#8083A3"} gutterBottom>
                Início: {formattedCreatedat}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color={"#8083A3"} gutterBottom>
                Ultima atualização: {formattedUpdatedat}
              </Typography>
            </div>
          </Box>
          <Box display={'flex'} justifyContent={'space-evenly'}>
            <Typography variant="h5" component="div">
              <Typography color={'#8083A3'}>Técnico</Typography>
              <Typography>{tracking.username}</Typography>
            </Typography>
            <Divider orientation='vertical' flexItem />
            <Typography variant="h5" component="div">
              <Typography color={'#8083A3'}>Status</Typography>
              {tracking.status === 'COMPLETED' && (
                <StatusIcon statusDescription={{ status: 'success', description: 'Finalizado' }} />
              )}
              {tracking.status === 'IN PROGRESS' && (
                <StatusIcon statusDescription={{ status: 'warn', description: 'Em andamento' }} />
              )}
              {tracking.status === 'FAILED' && (
                <StatusIcon statusDescription={{ status: 'failed', description: 'Com Falha' }} />
              )}
              {tracking.status === 'CANCELLED' && (
                <StatusIcon statusDescription={{ status: 'warn', description: 'Cancelado' }} />
              )}
            </Typography>
          </Box>
          <Divider />
          <Stepper orientation='vertical'>
            {tracking.tasks && tracking.tasks.map((task) => (
              <Step key={task.id_result} completed>
                <StepLabel
                  StepIconComponent={() => {
                    if (task.status === 'COMPLETED') {
                      return <StatusIcon iatStatusId={1} />;
                    } else if (task.status === 'IN PROGRESS') {
                      return <StatusIcon iatStatusId={3} />;
                    } else {
                      return <StatusIcon iatStatusId={2} />;
                    }
                  }}
                >
                  <Link to={`/iat/historic/${task.id_result}`} target="_blank" >{task.description}</Link>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
    </>
  );
};

export default TaskTrackingDetailCard;