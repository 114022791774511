import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { IatInfoData } from '../interfaces/IatInfoData';
import handleError from '../utils/handleError';
import { Autocomplete, Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { ApiResponse } from '../interfaces/ApiResponse';

type Props = {
  apiRequest: (id: string, searchStar?: boolean) => Promise<ApiResponse<IatInfoData>>;
  setEquipInfo: Dispatch<SetStateAction<IatInfoData | undefined>>;
  loading: { isLoading: boolean, setLoading: Dispatch<SetStateAction<boolean>>; };
  setError: Dispatch<SetStateAction<string>>;
  searchStar?: boolean;
};

const IatSearchIdVantive = ({ apiRequest, setEquipInfo, loading, setError, searchStar }: Props) => {
  const [vantiveId, setVantiveId] = useState<string>('');
  const [searchHistory, setSearchHistory] = useState<string[]>([]);

  useEffect(() => {
    // Load search history from localStorage when the component mounts
    const storedHistory = localStorage.getItem('iatVantiveIdSearchHistory');
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  const saveSearchHistory = (history: string[]) => {
    // Save search history to localStorage
    localStorage.setItem('iatVantiveIdSearchHistory', JSON.stringify(history));
  };

  const handleSearchVantiveId = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      loading.setLoading(true);
      setError("");
      setEquipInfo(undefined);
      if (vantiveId.trim() !== '' && !searchHistory.includes(vantiveId)) {
        // Add the search input to the history
        const updatedHistory = [vantiveId, ...searchHistory.slice(0, 9)];
        setSearchHistory(updatedHistory);
        // Save the updated history to localStorage
        saveSearchHistory(updatedHistory);
      }

      const res = await apiRequest(vantiveId, searchStar);
      setEquipInfo(res.data);
    } catch (error: any) {
      handleError({ error, setError });
    } finally {
      loading.setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSearchVantiveId}
      display={'flex'}
      width={'100%'}
    >
      <Autocomplete
        freeSolo
        options={searchHistory}
        fullWidth
        value={vantiveId}
        onInputChange={(e, value) => setVantiveId(value)}
        renderInput={(params) => <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            className: 'query-input',
            type: 'number'
          }}
          label="Vantive ID"
          required
        />
        }
      />
      <Button
        className='button'
        sx={{ marginLeft: '1em' }}
        variant="contained"
        color="primary"
        // onClick={handleSearchVantiveId}
        disabled={loading.isLoading}
        type='submit'
      >
        {loading.isLoading ? 'Buscando...' : 'Buscar'}
      </Button>
    </Box>
  );
};

export default IatSearchIdVantive;