import ativacaoLp from "../../assets/images/ativacao_lp.svg";
import ativacaoLpRouter from "../../assets/images/ativacao_lp_router.svg";
import checkConf from "../../assets/images/check_conf.svg";
import checkOlt from "../../assets/images/check_olt.svg";
import checkSwt from "../../assets/images/check_swt.svg";
import checkPrograma from "../../assets/images/check_programa.svg";
import manobra from "../../assets/images/manobra.svg";
import Hub from '../../components/Hub';

type Iat = {
  title: string;
  description: string;
  image: any;
  url: string;
};

type Category = {
  title: string,
  itens: Iat[],
};

const ativacaoItens: Iat[] = [
  {
    title: "Ativação LP",
    description: "Testes de link e conectividade com o último equipamento entregue ao cliente.",
    image: ativacaoLp,
    url: "/lp"
  },
  {
    title: "Ativação LP + Router",
    description: "Testes de link e conectividade com o roteador entregue ao cliente.",
    image: ativacaoLpRouter,
    url: "/lp-router"
  },
];

const diagnosticoItens: Iat[] = [
  {
    title: "Check SWT",
    description: "Check de todo tunel a partir da VLAN de rede configurada na interface do backbone até o elemento SWT com a VLAN de serviço encapsulada.",
    image: checkSwt,
    url: "/swt"
  },
  {
    title: "Check OLT",
    description: "Check de todo tunel a partir da VLAN de rede configurada na interface do backbone até a OLT, checando toda configuração da OLT.",
    image: checkOlt,
    url: "/olt"
  },
  {
    title: "Check programação",
    description: "Check de configuração de duplicidade de IP's, apontamentos de rotas para o backbone correspondente, de interface e de rota cliente.",
    image: checkPrograma,
    url: "/programming"
  },
  {
    title: "Check Configuração",
    description: "Obtenha insights valiosos acerca da experiência dos clientes em relação aos nossos produtos de conectividade. Além de recomendações que visam melhorar a satisfação dos nossos clientes.",
    image: checkConf,
    url: "/configuration"
  },
];

const servicosItens: Iat[] = [
  {
    title: "Manobra",
    description: "Obtenha insights valiosos acerca da experiência dos clientes em relação aos nossos produtos de conectividade. Além de recomendações que visam melhorar a satisfação dos nossos clientes.",
    image: manobra,
    url: ""
  },
];

const categories: Category[] = [
  {
    title: 'Ativação',
    itens: ativacaoItens
  },
  {
    title: 'Diagnóstico',
    itens: diagnosticoItens
  },
  {
    title: 'Serviços',
    itens: servicosItens
  },
];

const IatHub = () => {

  const headerTitle = 'Unindo o melhor das tecnologias.';
  const headerSubtitle = 'Com o IaT você tem um hub de serviços que oferecem diversas soluções em um só lugar!';

  return (
    <Hub header={{ title: headerTitle, subtitle: headerSubtitle }} categories={categories} />
  );
};

export default IatHub;