import styles from './IpLightInfo.module.css';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//External
import { Skeleton, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
//Interfaces
import { IPLightInfo } from '../../interfaces/IpLightInfo';
//Services
import { ipLightInfoRequest } from '../../services/apiWeb';
//Components
import IpLightCard from '../../components/IpLightCard';

const IpLightInfo = () => {

  const { id } = useParams();
  const [ipLight, setIpLight] = useState<IPLightInfo | null>(null);

  const navigate = useNavigate();

  const getIpLight = async (id: string) => {
    const res = await ipLightInfoRequest(id);
    setIpLight(res);
  };

  useEffect(() => {
    if (id) getIpLight(id);
  }, [id]);

  return (
    <div className='iplight-info-page'>
      <IconButton className={styles.close_button} onClick={() => { navigate(-1); }}>
        <CloseOutlinedIcon />
      </IconButton>
      {ipLight ? (
        <>
          <IpLightCard ipLight={ipLight.data} />
        </>
      ) : (
        <Skeleton variant='rectangular' sx={{ margin: '1em', height: '25em' }} />
      )}
    </div>
  );
};

export default IpLightInfo;