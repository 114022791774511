import styles from './Hub.module.css';
import { Card, CardMedia, Button, Box, Divider, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type Item = {
  title: string;
  description: string;
  image: any;
  url: string;
};

type Category = {
  title: string,
  itens: Item[],
};

type Props = {
  header: { title: string, subtitle: string; };
  categories: Category[];
};

const Hub = ({ header, categories }: Props) => {
  const navigate = useNavigate();

  const Header = () => (
    <div className={styles.header}>
      <h1 className={styles.header_title}>{header.title}</h1>
      <h2 className={styles.header_subtitle}>{header.subtitle}</h2>
    </div>
  );

  const ItemInfo = ({ item }: { item: Item; }) => (
    <>
      <Card className={styles.card}>
        <CardMedia component="img" alt={item.title} src={item.image} className={styles.icons} />
      </Card>
      <h3>{item.title}</h3>
      <p>{item.description}</p>
      <div>
        <Button variant='outlined' className='button_transparent' sx={{ width: "100%" }} onClick={() => navigate(item.url)}>
          Consulte Agora
        </Button>
      </div>
    </>
  );

  return (
    <div>
      <Header />
      <Box m={5}>
        {categories.map((category, index) => (
          <Box key={index} >
            <h2>{category.title}</h2>
            <Divider sx={{ marginBottom: '1em' }} />
            <Grid container rowSpacing={5} columnSpacing={{ xs: 20, md: 30 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {category.itens.map((item, index) => (
                <Grid className={styles.iat_info} item xs={2} sm={4} md={4} key={index} >
                  <ItemInfo item={item} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Hub;