import 'photoswipe/dist/photoswipe.css';
import styles from './PhotoViewer.module.css';
import { Gallery, Item } from 'react-photoswipe-gallery';

interface PhotoViewerProps {
  mainPhoto: string;
  relatedPhotos: string[];
}

const PhotoViewer = ({ mainPhoto, relatedPhotos }: PhotoViewerProps) => {
  // const smallItemStyles: React.CSSProperties = {
  //   cursor: 'pointer',
  //   objectFit: 'cover',
  //   width: '100%',
  //   maxHeight: '100%',
  // };
  return (
    <Gallery>
      <div className={styles.gallery}>
        <Item
          original={mainPhoto}
          thumbnail={mainPhoto}
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
            <>
              <img alt="main" ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src={mainPhoto} />
            </>
          )}
        </Item>
        {relatedPhotos && (
          relatedPhotos.map((photo, index) => (
            <Item
              key={index}
              original={photo}
              thumbnail={photo}
              width="1024"
              height="255"
            >
              {({ ref }) => (
                <img alt={`related-${index}`} style={{ height: 'auto' }} className={styles.img_hidden} ref={ref as React.MutableRefObject<HTMLImageElement>} />
              )}
            </Item>
          ))
        )}
      </div>
    </Gallery>
  );
};

export default PhotoViewer;
