import { Value } from "../interfaces/InternetFacility";

/**
 * Extract a new object in the Key Value form pair from a complex object
 * @param object The object to have the attributes extracted
 * @param attributeNames The attributes to be extracted in a Key-Value pair
 * @param attributeMap (Optional) An map to change the name of the original attribute. Ex: { originalAttributeName: 'newAttributeName' }
 * @returns 
 */
export const extractAttributes = (object: Value, attributeNames: string[], attributeMap: { [key: string]: string; } = {}) => {
  return attributeNames.map(attributeName => {
    const newName = attributeMap[attributeName] || attributeName;
    return { name: newName, value: object[attributeName as keyof Value] || '' };
  });
};