import React, { ReactNode } from 'react';
import { useUserContext } from '../contexts/UserContext';

type Props = {
  roles: string[],
  children: ReactNode,
};

const ProtectedComponent = ({ roles, children }: Props) => {
  const { userHasRole } = useUserContext();

  if (userHasRole(roles)) {
    return (
      <>{children}</>
    );
  }

  return (<></>);
};

export default ProtectedComponent;