import React from 'react';
import TextField, { TextFieldVariants } from '@mui/material/TextField';
import { InputCustom } from '../interfaces/InputCustom';

interface Props {
  data: Record<string, any>;
  labelMap: Record<string, InputCustom>;
  variant?: TextFieldVariants;
}

const DynamicView: React.FC<Props> = ({ data, labelMap, variant }) => {
  return (
    <>
      {Object.keys(labelMap).map((key) => {
        const computeValue = labelMap[key].value;
        let value;
        if (computeValue) {
          value = computeValue(data);
        } else {
          const nestedKeys = key.split('.');
          value = nestedKeys.reduce((obj, nestedKey) => obj && obj[nestedKey], data);
        }
        const label = labelMap[key].label;

        return (
          <TextField
            key={key}
            label={label}
            value={value ?? ''}
            variant={variant ?? "outlined"}
            margin="normal"
            InputProps={{
              readOnly: true
            }}
          />
        );
      })}
    </>
  );
};

export default DynamicView;
