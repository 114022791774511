import { KeycloakUser, KeycloakGroup } from "../interfaces/KeycloakAPI";
import { UserInfo } from "../interfaces/UserInfo";
import request from "../utils/requestUtil";

const API_WEB_AUTH_URL: string = process.env.REACT_APP_API_AUTH_BASE_URL ?? 'http://127.0.0.1:8000/api/auth/v1'; // Replace with your API base URL

async function requestApiAuth(url: string, method: string = 'GET', data: any = null, headers: any = {}, mock: boolean = false): Promise<any> {
  return request(API_WEB_AUTH_URL, url, method, data, headers);
}

export async function pendingUsersRequest(): Promise<UserInfo[]> {
  return await requestApiAuth(`/users/pending?segmento=b2b`, 'GET');
}

export async function approveUsersRequest(body: { id: string, role: string; segmento: string; }[]): Promise<UserInfo[]> {
  return await requestApiAuth(`/users/approve`, 'POST', body);
}

export async function getAllUsersRequest(username?: string): Promise<KeycloakUser[]> {
  // return await request(`http://localhost:8080`, `/api/app/v1/users`, 'GET');
  return await requestApiAuth(`/users${username ? `?username=${username}` : ''}`, 'GET');
}

export async function getAllGroupsRequest(): Promise<KeycloakGroup[]> {
  // return await request(`http://localhost:8080`, `/api/app/v1/groups`, 'GET');
  return await requestApiAuth(`/groups`, 'GET');
}

export async function getUserGroupsRequest(userId: string): Promise<KeycloakGroup[]> {
  // return await request(`http://localhost:8080`, `/api/app/v1/users/${userId}/groups`, 'GET');
  return await requestApiAuth(`/users/${userId}/groups`, 'GET');
}

export async function addUserGroupsRequest(userId: string, groupId: string): Promise<any[]> {
  // return await request(`http://localhost:8080`, `/api/app/v1/users/${userId}/groups/${groupId}`, 'PUT');
  return await requestApiAuth(`/users/${userId}/groups/${groupId}`, 'PUT');
}

export async function deleteUserGroupsRequest(userId: string, groupId: string): Promise<any[]> {
  // return await request(`http://localhost:8080`, `/api/app/v1/users/${userId}/groups/${groupId}`, 'DELETE');
  return await requestApiAuth(`/users/${userId}/groups/${groupId}`, 'DELETE');
}