import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Item, TransferList } from '../interfaces/TransferList';
import handleError from '../utils/handleError';

type MoveVerification = {
  function: (...args: any[]) => Promise<void>;
  parameters?: any,
};

type Props = {
  transferList: TransferList;
  functionMoveRigth?: MoveVerification;
  functionMoveLeft?: MoveVerification;
};


function not(a: readonly Item[], b: readonly Item[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly Item[], b: readonly Item[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly Item[], b: readonly Item[]) {
  return [...a, ...not(b, a)];
}

export default function SelectAllTransferList({ transferList, functionMoveLeft, functionMoveRigth }: Props) {
  const [checked, setChecked] = React.useState<readonly Item[]>([]);
  const [left, setLeft] = React.useState<readonly Item[]>(transferList.left);
  const [right, setRight] = React.useState<readonly Item[]>(transferList.right);
  const [error, setError] = React.useState('');

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: Item) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly Item[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly Item[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = async () => {
    setError('');
    if (functionMoveRigth) {
      handleCheckedRightWithVerification(functionMoveRigth);
    } else {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    }

  };

  const handleCheckedRightWithVerification = async (functionMoveRigth: MoveVerification) => {
    try {
      await functionMoveRigth.function(functionMoveRigth.parameters.userId, checked);
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const handleCheckedLeft = async () => {
    setError('');
    if (functionMoveLeft) {
      handleCheckedLeftWithVerification(functionMoveLeft);
    } else {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    }
  };

  const handleCheckedLeftWithVerification = async (functionMoveLeft: MoveVerification) => {
    try {
      await functionMoveLeft.function(functionMoveLeft.parameters.userId, checked);
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const customList = (title: React.ReactNode, items: readonly Item[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items.filter(item => !item.label.includes('admin')))}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: Item) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
              disabled={value.label.includes('admin')}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.label}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {error && <p className='error'>{error}</p>}
      <Grid item>{customList(transferList.leftLabel ?? 'Choices', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(transferList.rightLabel ?? 'Chosen', right)}</Grid>
    </Grid>
  );
}