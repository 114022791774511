import React from 'react';
import { IatTracking } from '../interfaces/IatTracking';
import { Card, CardContent, Typography, Divider, Stepper, Step, StepLabel } from '@mui/material';
import { Box } from '@mui/system';
import StatusIcon from './StatusIcon';
import { Task } from '../interfaces/HpsdTracking';
import { Link } from 'react-router-dom';
import { HpsdCategory } from '../utils/EnumUtils';

type Props = {
  task: Task;
};

/**
 * Component to display the Task Tracking information, as the actual status and the tasks executed.
 * @param tracking  The collection TaskTracking info returned from API
 * @returns 
 */
const TaskCircuitReworkDetailCard = ({ task }: Props) => {
  const formattedCreatedat = new Date(task.created_at).toLocaleString("pt-BR", { timeZone: 'UTC' });
  const formattedUpdatedat = new Date(task.updated_at).toLocaleString("pt-BR", { timeZone: 'UTC' });
  return (
    <>
      <h3>Ultima Task de Redesenho de Circuito</h3>
      <Card className='card' sx={{ backgroundColor: 'whitesmoke' }}>
        <CardContent>
          <Box display={'flex'}>
            <div>
              <Typography sx={{ fontSize: 14 }} color={"#8083A3"} gutterBottom>
                Início: {formattedCreatedat}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color={"#8083A3"} gutterBottom>
                Ultima atualização: {formattedUpdatedat}
              </Typography>
            </div>
          </Box>
          <Box display={'flex'} justifyContent={'space-evenly'}>
            <Typography variant="h5" component="div">
              <Typography color={'#8083A3'}>Ultima Ação</Typography>
              <Typography>{HpsdCategory[task.taskType as keyof typeof HpsdCategory]}</Typography>
            </Typography>
            <Divider orientation='vertical' flexItem />
            <Typography variant="h5" component="div">
              <Typography color={'#8083A3'}>Status</Typography>
              {task.status === 'COMPLETED' && (
                <StatusIcon statusDescription={{ status: 'success', description: 'Finalizado' }} />
              )}
              {task.status === 'IN PROGRESS' && (
                <StatusIcon statusDescription={{ status: 'warn', description: 'Em andamento' }} />
              )}
              {task.status === 'FAILED' && (
                <StatusIcon statusDescription={{ status: 'failed', description: 'Com Falha' }} />
              )}
              {task.status === 'CANCELLED' && (
                <StatusIcon statusDescription={{ status: 'warn', description: 'Cancelado' }} />
              )}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default TaskCircuitReworkDetailCard;