import styles from './App.module.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
// Context
// Hooks
// Utils
import { Roles } from './utils/EnumUtils';
// Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
// Pages
import Home from './pages/Home/Home';
import IpLight from './pages/IpLight/IpLight';
import IpLightInfo from './pages/IpLight/IpLightInfo';
import Suporte from './pages/Suporte/Suporte';
import ApprovUser from './pages/ApprovUser/ApprovUser';
import Unauthorized from './pages/Unauthorized';
import NotFound from './pages/NotFound';
import IpInternet from './pages/IpInternet/IpInternet';
import { useKeycloak } from '@react-keycloak/web';
import { CircularProgress } from '@mui/material';
import IatHub from './pages/IatHub/IatHub';
import Lp from './pages/Lp/Lp';
import LpRouter from './pages/LpRouter/LpRouter';
import Swt from './pages/Swt/Swt';
import IatHistoricDetail from './pages/IatHistoric/IatHistoricDetail';
import Olt from './pages/Olt/Olt';
import Programming from './pages/Programing/Programming';
import Configuration from './pages/Configuration/Configuration';
import IatHistoric from './pages/IatHistoric/IatHistoric';
import ManobraRecursos from './pages/Manobra/ManobraRecursos';
import ConsultVantive from './pages/ConsultVantive/ConsultVantive';
import Duplicity from './pages/Duplicity/Duplicity';
import ManobraIPs from './pages/Manobra/ManobraIPs';
import FenixHistoric from './pages/FenixHistoric/FenixHistoric';
import FenixHistoricDetail from './pages/FenixHistoric/FenixHistoricDetail';
import Profile from './pages/Profile/Profile';
import ManageUsers from './pages/ManageUsers/ManageUsers';
import CrudVivo2 from './pages/Vivo2/CrudVivo2';
import Vivo2Historic from './pages/Vivo2Historic/Vivo2Historic';
import Vivo2HistoricDetail from './pages/Vivo2Historic/Vivo2HistoricDetail';
import ManobraVlan from './pages/Manobra/ManobraVlan';

function App() {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login();
      }
    }
  }, [initialized]);

  if (!initialized || !keycloak.authenticated) {
    return (
      <div className={styles.app_loading}>
        <h1>MagicTool</h1>
        <CircularProgress />
      </div>
    );
  }

  // console.log(keycloak);

  return (
    <BrowserRouter>
      <Navbar />
      <div className={styles.App}>
        <Routes>
          {/* Public Routes */}
          {/* <Route path='/login' element={!isAuthenticated ? <Login /> : <Navigate to="/" />} /> */}
          {/* <Route path='/cadastro' element={!isAuthenticated ? <Cadastro /> : <Navigate to="/" />} />
          <Route path='/new-password' element={!isAuthenticated ? <ResetPass /> : <Navigate to="/" />} /> */}
          <Route path='/unauthorized' element={<Unauthorized />} />

          {/* Protected Routes */}
          <Route element={<ProtectedRoute allowedRoles={Object.values(Roles)} />}>
            <Route path='/' element={<Home />} />
            {/* IAT */}
            <Route path='/ip-light' element={<IpLight />} />
            <Route path='/ip-light/:id' element={<IpLightInfo />} />
            <Route path='/iat-hub' element={<IatHub />} />
            <Route path='/lp/:service' element={<Lp />} />
            <Route path='/lp-router/:service' element={<LpRouter />} />
            <Route path='/swt/:service' element={<Swt />} />
            <Route path='/olt/:service' element={<Olt />} />
            <Route path='/programming/:service' element={<Programming />} />
            <Route path='/configuration/:service' element={<Configuration />} />
            <Route path='/duplicity/:service' element={<Duplicity />} />
            <Route path='/iat/historic' element={<IatHistoric />} />
            <Route path='/iat/historic/:taskId' element={<IatHistoricDetail />} />
            {/* FENIX */}
            <Route path='/ip-internet' element={<IpInternet />} />
            <Route path='/manobra/recursos' element={<ManobraRecursos />} />
            <Route path='/manobra/ips' element={<ManobraIPs />} />
            <Route path='/manobra/vlan' element={<ManobraVlan />} />
            <Route path='/consultar-vantive' element={<ConsultVantive />} />
            <Route path='/fenix/historic' element={<FenixHistoric />} />
            <Route path='/fenix/historic/:taskId' element={<FenixHistoricDetail />} />
            {/* VIVO 2 */}
            <Route path='/vivo2/instalacao' element={<CrudVivo2 />} />
            <Route path='/vivo2/historic' element={<Vivo2Historic />} />
            <Route path='/vivo2/historic/:taskId' element={<Vivo2HistoricDetail />} />
            {/* <Route path='/profile' element={<Profile />} /> */}
          </Route>

          <Route element={<ProtectedRoute allowedRoles={[Roles.admin, Roles.avancado, Roles.suporte]} />}>
            <Route path='/suporte' element={<Suporte />} >
              <Route element={<ProtectedRoute allowedRoles={[Roles.admin, Roles.avancado, Roles.suporte]} />}>
                <Route path='approv-users' element={<ApprovUser />} />
              </Route>
              <Route element={<ProtectedRoute allowedRoles={[Roles.admin]} />}>
                <Route path='manage-users' element={<ManageUsers />} />
              </Route>
            </Route>
          </Route>

          {/* Not Found */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
