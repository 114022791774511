import axios, { AxiosRequestConfig, AxiosError } from "axios";
// import ApiError from "./ApiError";
import keycloak from "../config/keycloak";
import ApiError from "./ApiError";

async function request(baseUrl: string, url: string, method: string = 'GET', data: any = null, headers: any = {}): Promise<any> {
  const access_token = keycloak.token;
  const authHeaders = access_token ? { Authorization: `Bearer ${access_token}` } : {};
  const parameters = localStorage.getItem("debugMode") === 'true' ? { "mockup": localStorage.getItem("debugMode") } : {};


  const config: AxiosRequestConfig = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...authHeaders,
    },
    url: `${baseUrl}${url}`,
    data: data ? JSON.stringify(data) : undefined,
    params: parameters
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw new ApiError({ status: error.response?.status, code: error.code, message: error.message, cause: error.cause, responseData: error.response?.data });
      // throw error;
    }
    throw new Error(`API Error: ${error.message}`);
  }
}

export default request;