import styles from './IpInternet.module.css';
import { FormEvent, useEffect, useState } from 'react';
//External
import { Autocomplete, Box, Button, Skeleton, TextField } from '@mui/material';
//Interfaces
import { InternetFacility } from '../../interfaces/InternetFacility';
//Services
import { hpsdFacilitiesRequest, trackingHpsdListRequest } from '../../services/apiSys';
//Components
import handleError from '../../utils/handleError';
import IpInternetDisplay from '../../components/IpInternetDisplay';

const IpInternet = () => {
  const [searchId, setSearchId] = useState<string>('');
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [facilidadeResponse, setFacilidadeResponse] = useState<InternetFacility>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    // Load search history from localStorage when the component mounts
    const storedHistory = localStorage.getItem('internetIdSearchHistory');
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  const saveSearchHistory = (history: string[]) => {
    // Save search history to localStorage
    localStorage.setItem('internetIdSearchHistory', JSON.stringify(history));
  };

  const handleSearchId = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      setError("");
      setFacilidadeResponse(undefined);
      if (searchId.trim() !== '' && !searchHistory.includes(searchId)) {
        // Add the search input to the history
        const updatedHistory = [searchId, ...searchHistory.slice(0, 9)];
        setSearchHistory(updatedHistory);
        // Save the updated history to localStorage
        saveSearchHistory(updatedHistory);
      }
      // const res = await hpsdFacilitiesRequest(searchId);
      // const resT = await trackingHpsdListRequest(searchId);
      const resFacilidade = await hpsdFacilitiesRequest(searchId);
      setFacilidadeResponse(resFacilidade.data);
    } catch (error: any) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.main}>
      <Box
        component="form"
        onSubmit={handleSearchId}
        display={'flex'}
        width={'100%'}
      >
        <Autocomplete
          id='input-internet-id'
          freeSolo
          options={searchHistory}
          fullWidth
          value={searchId}
          onInputChange={(e, value) => setSearchId(value)}
          renderInput={(params) => <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              className: 'query-input',
            }}
            label="Designador de acesso"
            required
          />
          }
        />
        <Button
          className='button'
          sx={{ marginLeft: '1em' }}
          variant="contained"
          color="primary"
          disabled={loading}
          type='submit'
        >
          {loading ? 'Buscando...' : 'Buscar'}
        </Button>
      </Box>
      <Box
        width={'100%'}
        marginTop={1}
      >
        {facilidadeResponse &&
          <IpInternetDisplay facilityInfo={facilidadeResponse} />
        }
        {loading && <Skeleton variant='rounded' width='100%' height={500} />}
        {error && <p className='error'>{error}</p>}
      </Box>
    </div >
  );
};

export default IpInternet;