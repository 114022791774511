import styles from './HpsdResultDetail.module.css';
import { HpsdConfigCallback, Service, ServiceCharacteristic } from '../interfaces/HpsdConfigCallback';
import { Box, Card, Dialog, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLate from '@mui/icons-material/AssignmentLate';
import ArticleIcon from '@mui/icons-material/Article';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import StatusIcon from './StatusIcon';
import { NetworkCommandResult, IHpsdResultDetail } from '../interfaces/HpsdResultDetail';
import { useState } from 'react';
import { IdStatusResult } from '../utils/EnumUtils';
import saveAs from 'file-saver';


type Props = {
  service: Service;
};

type LogInfo = {
  designador?: string,
  status?: number;
  procedure?: string;
  result?: string;
  log?: string;
};

type ModalProps = {
  open: boolean;
  onClose: () => void;
  logInfo: LogInfo;
};

const HpsdResultDetail = ({ service }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedLogInfo, setSelectedLogInfo] = useState<LogInfo>({ designador: service.id });

  const handleOpenModal = (logInfo: LogInfo) => {
    setSelectedLogInfo(logInfo);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const geralStatus = service.serviceCharacteristic.find((charact) => charact.name.includes('ID_STATUS_'))?.value as string;
  const geralMsg = service.serviceCharacteristic.find((charact) => charact.name.includes('MENSAGEM_'))?.value as string;
  const geralAnalisys = service.serviceCharacteristic.find((charact) => charact.name.includes('ANALISE_'))?.value as string;
  const loginTecnico = service.serviceCharacteristic.find((charact) => charact.name.includes('LOGIN_TECNICO'))?.value as string;
  const resultTests = service.serviceCharacteristic.find((charact) => charact.name === 'RESULTADO_TESTES')?.value as IHpsdResultDetail[];

  const Header = () => (
    <Box className={styles.header}>
      <Card className={styles.card_header}>
        <div className={styles.card_header_content}>
          {geralStatus === '1' ? (
            <AssignmentTurnedInIcon
              color='primary'
              sx={{ fontSize: '3em' }}
            />
          ) : (
            <AssignmentLate
              color='primary'
              sx={{ fontSize: '3em' }}
            />
          )}
          <div>
            <Typography variant='h6' >Resultado</Typography>
            <Typography marginRight={1}>{geralMsg}</Typography>
          </div>
        </div>
      </Card>
      <Card className={styles.card_header}>
        <div className={styles.card_header_content}>
          <ArticleIcon
            color='primary'
            // fontSize='large'
            sx={{ fontSize: '3em' }}
          />
          <div>
            <Typography variant='h6' >Analise</Typography>
            <Typography marginRight={1}>{geralAnalisys}</Typography>
          </div>
        </div>
      </Card>
      <Card className={styles.card_header}>
        <div className={styles.card_header_content}>
          <AssignmentIndIcon
            color='primary'
            // fontSize='large'
            sx={{ fontSize: '3em' }}
          />
          <div>
            <Typography variant='h6' >Técnico</Typography>
            <Typography marginRight={1}>{loginTecnico}</Typography>
          </div>
        </div>
      </Card>
    </Box>
  );

  const ResultTable = ({ title, netCommands, headerColor }: { title: string; netCommands: NetworkCommandResult[], headerColor: string; }) => (
    <TableContainer component={Paper} className={styles.result_table} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4} style={{ background: headerColor }}>
              <Typography variant="h6">
                {title}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align="left">Procedimento</TableCell>
            <TableCell align="left">Resultado</TableCell>
            <TableCell align="right">Log</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {netCommands && netCommands.map((command) => (
            <TableRow
              key={command.step}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <StatusIcon iatStatusId={command.status} />
              </TableCell>
              <TableCell align="left">{command.procedureDescription}</TableCell>
              <TableCell align="left">{command.result}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => handleOpenModal(
                  { ...selectedLogInfo, procedure: command.procedureDescription, result: command.result, log: command.log, status: command.status }
                )}
                >
                  <InfoIcon
                    //  style={{ color: '#6C389D80' }} 
                    color='info'
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box>
      <Header />
      {resultTests.map((result, index) => (
        <ResultTable
          key={index}
          title={result.DescricaoTeste}
          netCommands={result.networkCommandResults}
          headerColor={!result.idResult ? 'darkgray' : result.idResult === 1 ? '#65B878' : '#F06D9E'}
        />
      ))}
      {selectedLogInfo && (
        <CustomModal open={isModalOpen} onClose={handleCloseModal} logInfo={selectedLogInfo} />
      )}
    </Box>
  );
};

const CustomModal = ({ open, onClose, logInfo }: ModalProps) => {
  const statusDescription = logInfo.status !== undefined ? IdStatusResult[logInfo.status] : '';

  const handleDownloadTxt = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    const blob = new Blob([logInfo.procedure + `\n\n`, logInfo.log ?? ''], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${logInfo.designador}-${logInfo.procedure}-log-${formattedDate}.txt`);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div>
        <IconButton onClick={handleDownloadTxt}>
          <Tooltip title="Salvar txt">
            <SaveIcon color='action' />
          </Tooltip>
        </IconButton>
      </div>
      <DialogTitle>
        {`${logInfo.designador} \n ${logInfo.procedure} - Resultado: ${logInfo.result} ${logInfo.status !== undefined ? (`${statusDescription}`) : ('')}`}
      </DialogTitle>
      <DialogContent>
        <pre>{logInfo.log}</pre>
      </DialogContent>
    </Dialog>
  );
};

export default HpsdResultDetail;