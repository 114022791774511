import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type ConfirmationDialogProps = {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  title?: string;
  content: string;
};

const ConfirmationDialog = ({ open, onClose, title, content }: ConfirmationDialogProps) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
      >
        {title && (
          <DialogTitle>
            {title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color='primary'>Cancelar</Button>
          <Button onClick={() => onClose(true)} color='secondary'>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;