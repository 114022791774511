import styles from './ApprovUser.module.css';
import { useContext, useEffect, useRef, useState } from 'react';
//External
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId, GridRowSelectionModel, GridValueGetterParams, ptBR, useGridApiRef } from '@mui/x-data-grid';
import { Box, Button, CircularProgress, MenuItem, Select } from '@mui/material';
//Services
import { approveUsersRequest, pendingUsersRequest } from '../../services/apiKeycloak';
//Interfaces
import { UserInfo } from '../../interfaces/UserInfo';
//Utils
import ApiError from '../../utils/ApiError';
//Components
import { Roles } from '../../utils/EnumUtils';
import { useUserContext } from '../../contexts/UserContext';
import handleError from '../../utils/handleError';
import { IApiError } from '../../interfaces/IApiError';
import { checkboxSelectionVisibleOnly } from '../../utils/Utils';

const ApprovUser = () => {

  const { userHasAdminRole, userHasRole } = useUserContext();
  const [pendingUserList, setPendingUserList] = useState<UserInfo[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [selectedRows, setSelectedRows] = useState<(UserInfo)[]>([]);
  const [roleList, setRoleList] = useState(Object.keys(Roles) as Array<keyof typeof Roles>);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [error, setError] = useState<string>("");
  const [warning, setWarning] = useState<string>('');

  const apiRef = useGridApiRef();

  const handleRowSelectionChange = (selectionModel: GridRowSelectionModel) => {
    const selected = checkboxSelectionVisibleOnly({ apiRef: apiRef.current, rowSelectionModel: selectionModel });
    setSelectionModel(selected);
    const selectedRowsData = selected.map((id) => pendingUserList.find((data) => data.id === id)!);
    setSelectedRows(selectedRowsData);
  };

  const handleRoleChange = (id: string, newRole: string) => {
    // Update the pending user list
    setPendingUserList((prevData) =>
      prevData.map((prevRow) =>
        prevRow.id === id ? { ...prevRow, role: newRole } : prevRow
      )
    );

    // Update the selected rows
    setSelectedRows((prevRow) =>
      prevRow.map((selected) =>
        selected.id === id ? { ...selected, role: newRole } : selected
      )
    );
  };

  const getPendingUserList = async () => {
    try {
      setLoadingSearch(true);
      const res = await pendingUsersRequest();
      setPendingUserList(res);
    } catch (error) {
      console.log(error);
      handleError({ error, setError });
    } finally {
      setLoadingSearch(false);
    }
  };

  const removeApprovedUsers = (approvedUsers: UserInfo[]) => {
    setPendingUserList((prevUsers) => prevUsers.filter((pendingUser) => !approvedUsers.some(approvedUser => approvedUser.id === pendingUser.id)));
  };

  const handleApproveSelectedRows = async () => {
    setLoadingApprove(true);
    setError('');
    setWarning('');
    const approvUsers = selectedRows.map((user) => {
      return {
        id: user.id,
        role: user.role,
        segmento: user.attributes.segmento[0]
      };
    });
    try {
      if (!approvUsers.every((user) => user.role)) {
        throw new Error('Não foi selecionado um perfil para um ou mais usuários selecionados');
      }
      const resp = await approveUsersRequest(approvUsers);
      // const resp = { data: [] };
      if (resp.length !== approvUsers.length) {
        setError('Um ou mais usuários não foram aprovados com sucesso');
      }
      // Remove the approved rows from the PendingUserList state 
      removeApprovedUsers(resp);
      // setPendingUserList((prevUsers) => prevUsers.filter((pendingUser) => !selectedRows.includes(pendingUser)));
    } catch (error) {
      setSelectedRows([]);
      setSelectionModel([]);
      setError("Erro na aprovação de usuário. Tente novamente mais tarde");
      if (error instanceof ApiError) {
        if (error.responseData && (error.responseData as IApiError).msg) setError((error.responseData as IApiError).msg!);
      } else if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setLoadingApprove(false);
    }
  };

  const filterRoleListByUserRole = () => {
    if (userHasRole([Roles.avancado])) {
      return roleList.filter((role) => role === 'suporte' || role === 'tecnico');
    } else if (userHasRole([Roles.suporte])) {
      return roleList.filter((role) => role === 'tecnico');
    } else {
      return roleList;
    }
  };

  useEffect(() => {
    getPendingUserList();
    setRoleList(filterRoleListByUserRole());
  }, []);

  const sortComparatorString = (a: string[], b: string[]) => a[0].localeCompare(b[0]);

  const columns: GridColDef<UserInfo>[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'username', headerName: 'RE', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'creation_date',
      headerName: 'Cadastro',
      flex: 1,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(new Date(value).toUTCString().slice(0, -4)),
    },
    {
      field: 'regional', headerName: 'Regional', flex: 1, valueGetter: (params) => params.row.attributes.regional, sortComparator: sortComparatorString
    },
    {
      field: 'segmento',
      headerName: 'Segmento',
      flex: 1,
      valueGetter: (params) => params.row.attributes.segmento,
      type: 'string',
      sortComparator: sortComparatorString
    },
    { field: 'perfil', headerName: 'Perfil solicitado', flex: 1, valueGetter: (params) => params.row.attributes.perfil, sortComparator: sortComparatorString },
    { field: 'sistema', headerName: 'Sistema', flex: 1, valueGetter: (params) => params.row.attributes.sistema, sortComparator: sortComparatorString },
    {
      field: 'role',
      headerName: 'Perfil à ser associado*',
      flex: 1,
      renderCell: (params) => (
        <Select
          fullWidth
          defaultValue={''}
          value={params.row.role ?? ''}
          onChange={(event) => handleRoleChange(params.row.id, event.target.value)}
        >
          {roleList
            .map((key, index) => (
              <MenuItem key={index} value={key}>{key}</MenuItem>
            ))}
        </Select>
      ),
    },
  ];

  return (
    <Box style={{ height: 500, width: '100%' }}>
      <h3>Aprovação de Usuários</h3>
      <div className={styles.button_div}>
        <Button variant='contained' className='button' disabled={selectedRows.length === 0 || loadingApprove} onClick={handleApproveSelectedRows}>
          {loadingApprove ? <CircularProgress size={24} color="inherit" /> : "Aprovar Selecionados"}
        </Button>
      </div>
      {error && <p className='error'>{error}</p>}
      {warning && <p className='warn'>{warning}</p>}
      <DataGrid
        apiRef={apiRef}
        rows={pendingUserList}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 15 },
          },
          sorting: {
            sortModel: [{ field: 'creation_date', sort: 'desc' }]
          }
        }}
        loading={loadingSearch}
        pageSizeOptions={[15, 30, 50]}
        checkboxSelection
        disableColumnSelector
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleRowSelectionChange}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
};

export default ApprovUser;