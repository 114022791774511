import { IPLightList } from "../interfaces/IPLightList";
import { IPLightInfo } from "../interfaces/IpLightInfo";
import { ResetPasswordToken } from "../interfaces/ResetPasswordToken";
import { LoginUser } from "../interfaces/LoginUser";
import { UserInfo } from "../interfaces/UserInfo";
import { PingResponse } from "../interfaces/PingResponse";
import request from "../utils/requestUtil";
import { FilesInfo } from "../interfaces/FilesInfo";
import { KeycloakGroup, KeycloakUser } from "../interfaces/KeycloakAPI";
import { IVivo2Circuit } from "../interfaces/IVivo2Circuit";
import { ApiResponse } from "../interfaces/ApiResponse";

const API_WEB_BASE_URL: string = process.env.REACT_APP_API_BASE_URL ?? 'http://127.0.0.1:8000/api/app/v1/web'; // Replace with your API base URL

async function requestApi(url: string, method: string = 'GET', data: any = null, headers: any = {}, mock: boolean = false): Promise<any> {
  return request(API_WEB_BASE_URL, url, method, data, headers);
}

async function requestApiWeb(url: string, method: string = 'GET', data: any = null, headers: any = {}, mock: boolean = false): Promise<any> {
  return request(API_WEB_BASE_URL + '/web', url, method, data, headers);
}

async function requestApiMobile(url: string, method: string = 'GET', data: any = null, headers: any = {}, mock: boolean = false): Promise<any> {
  return request(API_WEB_BASE_URL + '/mobile', url, method, data, headers);
}

export async function loginRequest(credentials: { username: string; password: string; }): Promise<LoginUser> {
  return await requestApiWeb('/user/login', 'POST', credentials);
}

export async function registerTokenRequest(username: string): Promise<ResetPasswordToken> {
  return await requestApiWeb(`/user/${username}/new-token`, 'POST');
}

export async function refreshTokenRequest(header: { Authorization: string; }): Promise<any> {
  return await requestApiWeb(`/user/refresh`, 'POST', null, header);
}

export async function resetPasswdRequest(username: string, body: { token: string; password: string; }): Promise<any> {
  return await requestApiWeb(`/user/${username}/reset-passwd`, 'POST', body);
}

export async function validateReCaptchaRequest(body: { token: string; secret_key: string; }): Promise<{ data: { success: boolean; }; }> {
  return await requestApiWeb(`/recaptcha`, 'POST', body);
}

export async function registerRequest(cadastro: { name: string; username: string; empresa: string; password: string; email: string; perfil: string; }): Promise<any> {
  return await requestApiWeb('/user/new-user', 'POST', cadastro);
}

export async function ipLightListRequest(): Promise<IPLightList> {
  return await requestApiWeb('/certiplight', 'GET');
}

export async function ipLightListFilterRequest(filter: string, value: string): Promise<IPLightList> {
  return await requestApiWeb(`/certiplight?${filter}=${value}`, 'GET');
}

export async function ipLightInfoRequest(id: string): Promise<IPLightInfo> {
  return await requestApiWeb(`/certiplight/${id}`, 'GET');
}

export async function pingRequest(body: { host: string; }): Promise<PingResponse> {
  return await requestApiWeb(`/ping`, 'POST', body);
}

export async function ipInternetListRequest(): Promise<IPLightList> {
  return await requestApiWeb('/certiplight', 'GET');
}

export async function ipInternetInfoRequest(id: string): Promise<IPLightInfo> {
  return await requestApiWeb(`/certiplight/${id}`, 'GET');
}

export async function filesInfoRequest(): Promise<FilesInfo> {
  return await requestApiMobile(`/files/info`, 'GET');
}

// --------------------------- VIVO 2 ---------------------------------

export async function getVivo2Installation(): Promise<ApiResponse<IVivo2Circuit[]>> {
  return await requestApi(`/vivo2/circuits`, `GET`);
}

export async function addVivo2Installation(installation: IVivo2Circuit): Promise<ApiResponse<IVivo2Circuit>> {
  // return await request(`http://localhost:8080`, `/api/app/v1/vivo2/circuits`, 'POST', installation);
  return await requestApi(`/vivo2/circuits`, `POST`, installation);
}

export async function editVivo2Installation(id: String, installation: IVivo2Circuit): Promise<IVivo2Circuit> {
  return await requestApi(`/vivo2/circuits/${id}`, `PUT`, installation);
}

export async function deleteVivo2Installation(installationId: string): Promise<IVivo2Circuit> {
  return await requestApi(`/vivo2/circuits/${installationId}`, `DELETE`);
}