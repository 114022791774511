import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';

type Props = {
  maxSecondsTime: number;
  interval?: number;
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-fl  ex' }}>
      <CircularProgress variant="determinate" size={100} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontSize={20}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
/**
 * Create a Circular Progress with a percentage label
 * @param {number} maxSecondsTime max time (in seconds) to complete the percentage at 100%  
 * @param {number} interval interval of time (in milliseconds) that the percentage will go up
 * @returns 
 */
export default function CircularWithValueLabel({ maxSecondsTime, interval = 1000 }: Props) {
  const [progress, setProgress] = useState(0);

  const ratio = (100 / maxSecondsTime) * (interval / 1000);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + ratio >= 100 ? 99 : prevProgress + ratio));
    }, interval);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}