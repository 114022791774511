import { Button, Skeleton, Autocomplete, TextField, Box } from '@mui/material';
import styles from './IatHistoric.module.css';
import { FormEvent, useEffect, useState } from 'react';
import handleError from '../../utils/handleError';
import IatHistoricActivities from '../../components/IatHistoricActivities';
import { IatHistoricConfig } from '../../interfaces/IatHistoricConfig';
import { trackingHistoricIatRequest } from '../../services/apiSys';

const IatHistoric = () => {
  const [idVantive, setIdVantive] = useState<string>('');
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [historic, setHistoric] = useState<IatHistoricConfig[] | undefined>(undefined);

  useEffect(() => {
    // Load search history from localStorage when the component mounts
    const storedHistory = localStorage.getItem('iatVantiveIdSearchHistory');
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  const saveSearchHistory = (history: string[]) => {
    // Save search history to localStorage
    localStorage.setItem('iatVantiveIdSearchHistory', JSON.stringify(history));
  };

  const handleSearchVantiveId = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      setError("");
      setHistoric(undefined);

      if (idVantive.trim() !== '' && !searchHistory.includes(idVantive)) {
        // Add the search input to the history
        const updatedHistory = [idVantive, ...searchHistory.slice(0, 9)];
        setSearchHistory(updatedHistory);
        // Save the updated history to localStorage
        saveSearchHistory(updatedHistory);
      }

      const resp = await trackingHistoricIatRequest([{ key: 'idVantive', value: idVantive }]);
      setHistoric(resp.data ?? []);
    } catch (error: any) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='main'>
      <h3 className={styles.title}>Consultar Testes</h3>
      <Box
        component="form"
        onSubmit={handleSearchVantiveId}
        display={'flex'}
        width={'100%'}
      >
        <Autocomplete
          freeSolo
          options={searchHistory}
          fullWidth
          value={idVantive}
          onInputChange={(e, value) => setIdVantive(value)}
          renderInput={(params) => <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              className: 'query-input',
              type: 'number'
            }}
            label="Vantive ID"
            required
          />
          }
        />
        <Button
          className='button'
          sx={{ marginLeft: '1em' }}
          variant="contained"
          color="primary"
          // disabled={loading}
          type='submit'
        >
          Buscar
        </Button>
      </Box>
      {historic && (
        <IatHistoricActivities historic={historic} />
      )}
      {error && <p className='error'>{error}</p>}
      {loading &&
        <Skeleton variant='rounded' width='100%' height={500} />
      }
    </div>
  );
};

export default IatHistoric;